import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Modal, Box, IconButton, useTheme, useMediaQuery } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { ProgramCard as ProgramCardType } from '../../data/programCards';

interface ProgramCardProps {
  card: ProgramCardType;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const ProgramCard: React.FC<ProgramCardProps> = ({ card, onClick }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedCard, setSelectedCard] = useState<ProgramCardType | null>(null);
  const [cardPosition, setCardPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });

  const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const element = event.currentTarget;
    const rect = element.getBoundingClientRect();
    setCardPosition({
      top: rect.top + window.scrollY,
      left: rect.left,
      width: rect.width,
      height: rect.height
    });
    setSelectedCard(card);
    if (onClick) {
      onClick(event);
    }
  };

  const handleClose = () => {
    setSelectedCard(null);
  };

  return (
    <>
      <Card
        component={motion.div}
        whileHover={{ scale: 1.05 }}
        onClick={handleCardClick}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
          }
        }}
      >
        <CardMedia
          component="img"
          height="200"
          image={card.image}
          alt={card.titleKey}
        />
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: '100px' }}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              lineHeight: 1.2,
            }}
          >
            {t(card.titleKey)}
          </Typography>
        </CardContent>
      </Card>

      <Modal
        open={selectedCard !== null}
        onClose={handleClose}
        closeAfterTransition
        keepMounted={false}
        disableScrollLock={false}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            }
          }
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '90%',
            maxWidth: '1200px',
            maxHeight: '90vh',
            margin: '20px auto',
            outline: 'none',
            borderRadius: '8px',
            overflow: 'hidden'
          }}
        >
          <motion.div
            initial={{
              opacity: 0,
              y: 20
            }}
            animate={{
              opacity: 1,
              y: 0
            }}
            exit={{
              opacity: 0,
              y: 20
            }}
            transition={{
              type: "spring",
              stiffness: 200,
              damping: 30
            }}
            style={{
              backgroundColor: '#fff',
              borderRadius: '8px',
              boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
              overflow: 'hidden',
              height: '100%'
            }}
          >
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                position: 'relative'
              }}
            >
              <IconButton
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: 'white',
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  },
                  zIndex: 2
                }}
              >
                <CloseIcon />
              </IconButton>

              <Box
                sx={{
                  width: isMobile ? '100%' : '50%',
                  height: isMobile ? '40%' : '90vh',
                  position: 'relative',
                  overflow: 'hidden'
                }}
              >
                <motion.img
                  initial={{ scale: 1.2, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ delay: 0.2, duration: 0.5 }}
                  src={card.image}
                  alt={t(card.titleKey)}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
              </Box>

              <Box
                sx={{
                  width: isMobile ? '100%' : '50%',
                  height: isMobile ? '60%' : '90vh',
                  padding: 4,
                  overflow: 'auto',
                  backgroundColor: '#fff',
                  ...(isMobile && {
                    maxHeight: '60vh',
                    overflowY: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    '&::-webkit-scrollbar': {
                      width: '6px',
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#888',
                      borderRadius: '3px',
                      '&:hover': {
                        backgroundColor: '#555',
                      },
                    },
                  }),
                }}
              >
                <Typography variant="h4" gutterBottom>
                  {t(card.titleKey)}
                </Typography>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    whiteSpace: 'pre-line',
                    ...(isMobile && {
                      fontSize: '0.9rem',
                      lineHeight: 1.6,
                    }),
                  }}
                >
                  {t(card.contentKey)}
                </Typography>
              </Box>
            </Box>
          </motion.div>
        </Box>
      </Modal>
    </>
  );
};

export default ProgramCard;
