import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { Trans } from 'react-i18next';
import PageContainer from '../components/PageContainer';
import { programCards } from '../data/programCards';
import ProgramCard from '../components/ProgramCard/ProgramCard';

interface ProgramCard {
  id: number;
  titleKey: string;
  image: string;
  contentKey: string;
}

const Program: React.FC = () => {
  const handleCardClick = (card: ProgramCard, event: React.MouseEvent<HTMLDivElement>) => {
    // This function is not being used, consider removing it
  };

  return (
    <PageContainer>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, py: 8 }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h2" component="h1" gutterBottom align="center" sx={{ mb: 6 }}>
            <Trans i18nKey="program.title" />
          </Typography>
        </motion.div>

        <Grid container spacing={4}>
          {programCards.map((card, index) => (
            <Grid item xs={12} md={4} key={card.id}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <ProgramCard card={card} />
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </PageContainer>
  );
};

export default Program;
