import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Typography, Paper, Box } from '@mui/material';
import { ref, onValue } from 'firebase/database';
import { db } from '../../firebase/config';
import { TranslatedContent } from '../admin/AdminNews';
import { getLangCode } from '../../utils/langCode';

interface NewsItem {
  id: string;
  title: TranslatedContent;
  description: TranslatedContent;
  content: Array<{ type: string, value: TranslatedContent | string }>;
  imageUrl: string;
  useExcerpt: boolean;
  expirationDate: string;
  createdAt: string;
}

const StyledPaper = styled(Paper)({
  padding: '2rem',
  backgroundColor: '#383838',
  color: '#ffffff',
  marginTop: '2rem',
});

const StyledImage = styled('img')({
  width: '100%',
  maxHeight: '400px',
  objectFit: 'cover',
  marginBottom: '2rem',
});

const ContentBlock = ({ type, value }: { type: string, value: any }) => {
  switch (type) {
    case 'title':
      return (
        <Typography variant="h4" component="h2" gutterBottom>
          {value[getLangCode()]}
        </Typography>
      );
    case 'paragraph':
      return (
        <Typography paragraph>
          {value[getLangCode()]}
        </Typography>
      );
    case 'image':
      return (
        <Box sx={{ my: 2 }}>
          <img src={value} alt="" style={{ maxWidth: '100%', height: 'auto' }} />
        </Box>
      );
    default:
      return null;
  }
};

const NewsDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [news, setNews] = useState<NewsItem | null>(null);

  useEffect(() => {
    if (!id) return;

    const newsRef = ref(db, `news/${id}`);
    const unsubscribe = onValue(newsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setNews(data);
      } else {
        setNews(null);
      }
    });

    return () => unsubscribe();
  }, [id]);

  if (!news) {
    return (
      <Container>
        <StyledPaper>
          <Typography variant="h4">News not found</Typography>
        </StyledPaper>
      </Container>
    );
  }

  return (
    <Container>
      <StyledPaper>
        {news.imageUrl && (
          <StyledImage src={news.imageUrl} alt={news.title.en} />
        )}
        <Typography variant="h3" gutterBottom>
          {news.title[getLangCode()]}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          {new Date(news.createdAt).toLocaleDateString()}
        </Typography>
        <Typography variant="body1" paragraph>
          {news.description[getLangCode()]}
        </Typography>
        <Box sx={{ mt: 4 }}>
          {news.content.map((block, index) => (
            <ContentBlock key={index} type={block.type} value={block.value} />
          ))}
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default NewsDetail;
