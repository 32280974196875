import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { DragDropContext, Droppable, Draggable, DropResult, DroppableProvided, DraggableProvided } from 'react-beautiful-dnd';
import { Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

interface ProgramItem {
  id: string;
  title: string;
  content: string;
  imageUrl: string;
  order: number;
}

const Container = styled('div')({
  maxWidth: '1200px',
  margin: '0 auto',
});

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2rem',
  '& h1': {
    color: '#5ba85b',
    textShadow: '0 0 10px rgba(91, 168, 91, 0.3)',
  },
});

const ProgramList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});

const ProgramCard = styled('div')({
  backgroundColor: '#383838',
  borderRadius: '8px',
  padding: '1rem',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 8px rgba(91, 168, 91, 0.2)',
  },
});

const ProgramImage = styled('img')({
  width: '100px',
  height: '100px',
  objectFit: 'cover',
  borderRadius: '4px',
});

const ProgramContent = styled('div')({
  flex: 1,
  '& h3': {
    color: '#ffffff',
    marginBottom: '0.5rem',
  },
  '& p': {
    color: '#cccccc',
    fontSize: '0.9rem',
  },
});

const ActionButtons = styled('div')({
  display: 'flex',
  gap: '0.5rem',
});

const AdminProgram: React.FC = () => {
  const [items, setItems] = useState<ProgramItem[]>([
    // Sample data
    {
      id: '1',
      title: 'Sample Program Item',
      content: 'This is a sample program item content.',
      imageUrl: 'https://via.placeholder.com/100',
      order: 0,
    },
  ]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<ProgramItem | null>(null);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    // Update order numbers
    const updatedItems = newItems.map((item, index) => ({
      ...item,
      order: index,
    }));

    setItems(updatedItems);
  };

  const handleEdit = (item: ProgramItem) => {
    setCurrentItem(item);
    setIsDialogOpen(true);
  };

  const handleAdd = () => {
    setCurrentItem(null);
    setIsDialogOpen(true);
  };

  const handleSave = () => {
    // TODO: Implement save logic
    setIsDialogOpen(false);
  };

  return (
    <Container>
      <Header>
        <h1>Program Management</h1>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAdd}
          sx={{
            backgroundColor: '#5ba85b',
            '&:hover': { backgroundColor: '#4c8f4c' },
          }}
        >
          Add New Item
        </Button>
      </Header>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="program-list">
          {(provided: DroppableProvided) => (
            <ProgramList
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided: DraggableProvided) => (
                    <ProgramCard
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <div {...provided.dragHandleProps}>
                        <DragIndicatorIcon />
                      </div>
                      <ProgramImage src={item.imageUrl} alt={item.title} />
                      <ProgramContent>
                        <h3>{item.title}</h3>
                        <p>{item.content}</p>
                      </ProgramContent>
                      <ActionButtons>
                        <IconButton onClick={() => handleEdit(item)} size="small" sx={{ color: '#5ba85b' }}>
                          <EditIcon />
                        </IconButton>
                        <IconButton size="small" sx={{ color: '#ff4444' }}>
                          <DeleteIcon />
                        </IconButton>
                      </ActionButtons>
                    </ProgramCard>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ProgramList>
          )}
        </Droppable>
      </DragDropContext>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>{currentItem ? 'Edit Program Item' : 'Add New Program Item'}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Title"
            margin="normal"
            defaultValue={currentItem?.title}
          />
          <TextField
            fullWidth
            label="Content"
            margin="normal"
            multiline
            rows={4}
            defaultValue={currentItem?.content}
          />
          <TextField
            fullWidth
            label="Image URL"
            margin="normal"
            defaultValue={currentItem?.imageUrl}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSave} variant="contained" sx={{ backgroundColor: '#5ba85b' }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminProgram;
