import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Avatar,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ref, onValue, update } from 'firebase/database';
import { db } from '../../firebase/config';

interface PollWorker {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  region: string;
  city: string;
  pollingStation: string;
  availability: string;
  experience: string;
  status: 'pending' | 'approved' | 'rejected';
  createdAt: string;
}

const Container = styled('div')({
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '2rem',
});

const Header = styled('div')({
  marginBottom: '2rem',
  '& h1': {
    color: '#5ba85b',
    textShadow: '0 0 10px rgba(91, 168, 91, 0.3)',
  },
});

const WorkerCard = styled(Card)({
  backgroundColor: '#383838',
  color: '#ffffff',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(91, 168, 91, 0.2)',
  },
});

const DetailItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  marginBottom: '0.5rem',
  '& svg': {
    color: '#5ba85b',
  },
});

const StatusChip = styled(Chip)(({ status }: { status: string }) => ({
  backgroundColor: 
    status === 'approved' ? '#5ba85b' :
    status === 'pending' ? '#ffa726' : '#ef5350',
  color: '#ffffff',
}));

const AdminPollWorkers: React.FC = () => {
  const [selectedWorker, setSelectedWorker] = useState<PollWorker | null>(null);
  const [workers, setWorkers] = useState<PollWorker[]>([]);

  useEffect(() => {
    const workersRef = ref(db, 'PollWorkers');
    const unsubscribe = onValue(workersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const workersArray = Object.entries(data).map(([id, worker]: [string, any]) => ({
          id,
          ...worker,
        }));
        setWorkers(workersArray);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleStatusChange = async (workerId: string, newStatus: 'approved' | 'rejected') => {
    try {
      const workerRef = ref(db, `PollWorkers/${workerId}`);
      await update(workerRef, { status: newStatus });
    } catch (error) {
      console.error('Error updating worker status:', error);
    }
  };

  return (
    <Container>
      <Header>
        <h1>Poll Workers Management</h1>
      </Header>

      <Grid container spacing={3}>
        {workers.map((worker) => (
          <Grid item xs={12} sm={6} md={4} key={worker.id}>
            <WorkerCard onClick={() => setSelectedWorker(worker)}>
              <CardContent>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
                  <Avatar sx={{ width: 60, height: 60 }}>
                    {worker.firstName[0]}{worker.lastName[0]}
                  </Avatar>
                  <div>
                    <Typography variant="h6">{worker.firstName} {worker.lastName}</Typography>
                    <StatusChip
                      label={worker.status}
                      status={worker.status}
                      size="small"
                    />
                  </div>
                </div>
                <DetailItem>
                  <LocationOnIcon />
                  <Typography>{worker.region}, {worker.city}</Typography>
                </DetailItem>
                <DetailItem>
                  <AssignmentIcon />
                  <Typography>{worker.pollingStation}</Typography>
                </DetailItem>
              </CardContent>
            </WorkerCard>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={!!selectedWorker}
        onClose={() => setSelectedWorker(null)}
        maxWidth="md"
        fullWidth
      >
        {selectedWorker && (
          <>
            <DialogTitle>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <Avatar sx={{ width: 80, height: 80 }}>
                    {selectedWorker.firstName[0]}{selectedWorker.lastName[0]}
                  </Avatar>
                  <div>
                    <Typography variant="h6">
                      {selectedWorker.firstName} {selectedWorker.lastName}
                    </Typography>
                    <StatusChip
                      label={selectedWorker.status}
                      status={selectedWorker.status}
                    />
                  </div>
                </div>
                {selectedWorker.status === 'pending' && (
                  <div>
                    <IconButton
                      color="success"
                      onClick={() => handleStatusChange(selectedWorker.id, 'approved')}
                    >
                      <CheckCircleIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleStatusChange(selectedWorker.id, 'rejected')}
                    >
                      <CancelIcon />
                    </IconButton>
                  </div>
                )}
              </div>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" color="#5ba85b" gutterBottom>
                    Contact Information
                  </Typography>
                  <DetailItem>
                    <EmailIcon />
                    <Typography>{selectedWorker.email}</Typography>
                  </DetailItem>
                  <DetailItem>
                    <PhoneIcon />
                    <Typography>{selectedWorker.phone}</Typography>
                  </DetailItem>
                  <DetailItem>
                    <LocationOnIcon />
                    <Typography>
                      {selectedWorker.region}, {selectedWorker.city}
                    </Typography>
                  </DetailItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" color="#5ba85b" gutterBottom>
                    Assignment Details
                  </Typography>
                  <DetailItem>
                    <AssignmentIcon />
                    <Typography>{selectedWorker.pollingStation}</Typography>
                  </DetailItem>
                  <Typography variant="subtitle1" color="#5ba85b" sx={{ mt: 2 }} gutterBottom>
                    Availability
                  </Typography>
                  <Typography paragraph>
                    {selectedWorker.availability}
                  </Typography>
                  <Typography variant="subtitle1" color="#5ba85b" gutterBottom>
                    Experience
                  </Typography>
                  <Typography paragraph>
                    {selectedWorker.experience}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Applied on: {new Date(selectedWorker.createdAt).toLocaleDateString()}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>
    </Container>
  );
};

export default AdminPollWorkers;
