import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Outlet, NavLink, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase/config';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CampaignIcon from '@mui/icons-material/Campaign';
import GroupIcon from '@mui/icons-material/Group';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import LogoutIcon from '@mui/icons-material/Logout';
import EmailIcon from '@mui/icons-material/Email';
import { Avatar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const LayoutContainer = styled('div')({
  display: 'flex',
  minHeight: '100vh',
  backgroundColor: '#f5f5f5',
});

const Sidebar = styled('nav')<{ isOpen: boolean }>(({ isOpen }) => ({
  width: isOpen ? '250px' : '70px',
  backgroundColor: '#2c3e50',
  transition: 'width 0.3s ease',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  height: '100vh',
  zIndex: 1000,
}));

const MainContent = styled('div')<{ sidebarOpen: boolean }>(({ sidebarOpen }) => ({
  flexGrow: 1,
  marginLeft: sidebarOpen ? '250px' : '70px',
  transition: 'margin-left 0.3s ease',
  backgroundColor: '#f5f5f5',
}));

const Header = styled('div')({
  height: '64px',
  backgroundColor: '#ffffff',
  borderBottom: '1px solid #e0e0e0',
  display: 'flex',
  alignItems: 'center',
  padding: '0 1.5rem',
  justifyContent: 'space-between',
});

const Logo = styled('div')<{ isOpen: boolean }>(({ isOpen }) => ({
  padding: '1rem',
  color: '#ffffff',
  fontSize: isOpen ? '1.5rem' : '0',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  justifyContent: isOpen ? 'flex-start' : 'center',
  transition: 'all 0.3s ease',
  height: '64px',
  borderBottom: '1px solid rgba(255,255,255,0.1)',
}));

const NavItem = styled(NavLink)({
  padding: '1rem',
  color: '#ffffff',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#34495e',
  },
  '&.active': {
    backgroundColor: '#3498db',
    '&:hover': {
      backgroundColor: '#2980b9',
    },
  },
  '& svg': {
    fontSize: '1.5rem',
  },
});

const NavText = styled('span')<{ isOpen: boolean }>(({ isOpen }) => ({
  opacity: isOpen ? 1 : 0,
  visibility: isOpen ? 'visible' : 'hidden',
  transition: 'all 0.3s ease',
}));

const UserSection = styled('div')({
  marginTop: 'auto',
  padding: '1rem',
  borderTop: '1px solid rgba(255,255,255,0.1)',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  color: '#ffffff',
});

const AdminLayout: React.FC = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/admin/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <LayoutContainer>
      <Sidebar isOpen={isSidebarOpen}>
        <Logo isOpen={isSidebarOpen}>
          {isSidebarOpen ? 'Admin Panel' : 'A'}
        </Logo>
        <NavItem to="/dashboard/home">
          <DashboardIcon />
          <NavText isOpen={isSidebarOpen}>Dashboard</NavText>
        </NavItem>
        <NavItem to="/dashboard/program">
          <CampaignIcon />
          <NavText isOpen={isSidebarOpen}>Program</NavText>
        </NavItem>
        <NavItem to="/dashboard/news">
          <NewspaperIcon />
          <NavText isOpen={isSidebarOpen}>News</NavText>
        </NavItem>
        <NavItem to="/dashboard/messages">
          <EmailIcon />
          <NavText isOpen={isSidebarOpen}>Messages</NavText>
        </NavItem>
        <NavItem to="/dashboard/poll-workers">
          <GroupIcon />
          <NavText isOpen={isSidebarOpen}>Poll Workers</NavText>
        </NavItem>
        <NavItem to="/dashboard/donations">
          <VolunteerActivismIcon />
          <NavText isOpen={isSidebarOpen}>Donations</NavText>
        </NavItem>
        <UserSection>
          <Avatar sx={{ width: 32, height: 32 }}>A</Avatar>
          {isSidebarOpen && (
            <>
              <div>
                <div style={{ fontWeight: 'bold' }}>Admin</div>
                <small style={{ opacity: 0.7 }}>Administrator</small>
              </div>
              <IconButton
                onClick={handleLogout}
                sx={{ marginLeft: 'auto', color: '#ffffff' }}
                size="small"
              >
                <LogoutIcon />
              </IconButton>
            </>
          )}
        </UserSection>
      </Sidebar>

      <MainContent sidebarOpen={isSidebarOpen}>
        <Header>
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon />
          </IconButton>
        </Header>
        <Outlet />
      </MainContent>
    </LayoutContainer>
  );
};

export default AdminLayout;
