import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Box,
  InputAdornment,
  Button
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import TitleIcon from '@mui/icons-material/Title';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ContentBlock, ContentBlockType, TitleBlock } from './types';

const EditorContainer = styled('div')({
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  padding: '1rem',
});

const BlockContainer = styled('div')({
  marginBottom: '1rem',
  backgroundColor: '#f8f9fa',
  borderRadius: '4px',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
});

const BlockHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '0.5rem',
  gap: '0.5rem',
  borderBottom: '1px solid #e9ecef',
  '& .drag-handle': {
    cursor: 'grab',
    color: '#6c757d',
  },
  '& .right-actions': {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }
});

const BlockContent = styled('div')({
  padding: '1rem',
});

const AddButton = styled(IconButton)({
  marginTop: '1rem',
});

interface ContentEditorProps {
  content: ContentBlock[];
  onChange: (content: ContentBlock[]) => void;
  onImageSelect?: (blockId: string, file: File) => void;
}

const ContentEditor: React.FC<ContentEditorProps> = ({ content, onChange, onImageSelect }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(true);
  }, []);

  const handleAddClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddBlockType = (type: ContentBlockType) => {
    const newBlock: ContentBlock = {
      id: `block-${Date.now()}`,
      type,
      order: content.length,
      ...(type === 'title' && {
        content: '',
        fontSize: 24,
        fontWeight: 600,
      }),
      ...(type === 'paragraph' && {
        content: '',
      }),
      ...(type === 'image' && {
        url: '',
        width: 800,
        height: 400,
        alt: '',
      }),
    } as ContentBlock;

    onChange([...content, newBlock]);
    setAnchorEl(null);
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(content);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const reorderedItems = items.map((item, index) => ({
      ...item,
      order: index,
    }));

    onChange(reorderedItems);
  };

  const handleDeleteBlock = (blockId: string) => {
    onChange(content.filter(block => block.id !== blockId));
  };

  const handleContentChange = (blockId: string, newContent: string) => {
    onChange(content.map(block =>
      block.id === blockId ? { ...block, content: newContent } : block
    ));
  };

  const handleTitlePropertyChange = (blockId: string, property: 'fontSize' | 'fontWeight', value: number) => {
    onChange(content.map(block =>
      block.id === blockId && block.type === 'title'
        ? { ...block, [property]: value }
        : block
    ));
  };

  const handleImageSelect = (blockId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && onImageSelect) {
      onImageSelect(blockId, file);
    }
  };

  const renderBlockContent = (block: ContentBlock) => {
    switch (block.type) {
      case 'title':
        return (
          <Box>
            <TextField
              fullWidth
              variant="standard"
              value={block.content}
              onChange={(e) => handleContentChange(block.id, e.target.value)}
              InputProps={{
                style: {
                  fontSize: `${block.fontSize}px`,
                  fontWeight: block.fontWeight,
                },
              }}
              placeholder="Enter title..."
            />
          </Box>
        );
      case 'paragraph':
        return (
          <TextField
            fullWidth
            multiline
            variant="standard"
            value={block.content}
            onChange={(e) => handleContentChange(block.id, e.target.value)}
            placeholder="Enter paragraph text..."
          />
        );
      case 'image':
        return (
          <div>
            {block.url ? (
              <img
                src={block.url}
                alt={block.alt}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            ) : (
              <div style={{
                width: '100%',
                height: '200px',
                backgroundColor: '#e9ecef',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <ImageIcon style={{ fontSize: '48px', color: '#adb5bd' }} />
              </div>
            )}
          </div>
        );
    }
  };

  return (
    <EditorContainer>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="content-blocks-list">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ minHeight: '50px' }}
            >
              {content.map((block, index) => (
                <Draggable
                  key={block.id}
                  draggableId={block.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <BlockContainer
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        ...provided.draggableProps.style,
                        opacity: snapshot.isDragging ? 0.8 : 1
                      }}
                    >
                      <BlockHeader>
                        <div {...provided.dragHandleProps} className="drag-handle">
                          <DragIndicatorIcon />
                        </div>
                        {block.type === 'title' && (
                          <>
                            <TextField
                              type="number"
                              size="small"
                              value={(block as TitleBlock).fontSize}
                              onChange={(e) => handleTitlePropertyChange(
                                block.id,
                                'fontSize',
                                Number(e.target.value)
                              )}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">px</InputAdornment>,
                              }}
                              sx={{ width: 100 }}
                            />
                            <TextField
                              type="number"
                              size="small"
                              value={(block as TitleBlock).fontWeight}
                              onChange={(e) => handleTitlePropertyChange(
                                block.id,
                                'fontWeight',
                                Number(e.target.value)
                              )}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">weight</InputAdornment>,
                              }}
                              sx={{ width: 120 }}
                            />
                          </>
                        )}
                        {block.type === 'image' && (
                          <Button
                            component="label"
                            size="small"
                            variant="outlined"
                            startIcon={<ImageIcon />}
                          >
                            Change Image
                            <input
                              type="file"
                              hidden
                              accept="image/*"
                              onChange={handleImageSelect(block.id)}
                            />
                          </Button>
                        )}
                        <div className="right-actions">
                          <IconButton
                            size="small"
                            onClick={() => handleDeleteBlock(block.id)}
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </BlockHeader>
                      <BlockContent>
                        {renderBlockContent(block)}
                      </BlockContent>
                    </BlockContainer>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button onClick={handleAddClick} variant="contained" color="primary">
        <AddIcon /> Add Block      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleAddBlockType('title')}>
          <TitleIcon sx={{ mr: 1 }} /> Title
        </MenuItem>
        <MenuItem onClick={() => handleAddBlockType('paragraph')}>
          <TextFieldsIcon sx={{ mr: 1 }} /> Paragraph
        </MenuItem>
        <MenuItem onClick={() => handleAddBlockType('image')}>
          <ImageIcon sx={{ mr: 1 }} /> Image
        </MenuItem>
      </Menu>
    </EditorContainer>
  );
};

export default ContentEditor;
