import React, { useState } from 'react';
import { Box, IconButton, useTheme, useMediaQuery } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Video {
  url: string;
  title: string;
}

interface VideoCarousel3DProps {
  videos: Video[];
}

const VideoCarousel3D: React.FC<VideoCarousel3DProps> = ({ videos }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? videos.length - 1 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === videos.length - 1 ? 0 : prevIndex + 1));
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (isMobile) {
      if (event.key === 'ArrowUp') {
        goToPrevious();
      } else if (event.key === 'ArrowDown') {
        goToNext();
      }
    } else {
      if (event.key === 'ArrowLeft') {
        goToPrevious();
      } else if (event.key === 'ArrowRight') {
        goToNext();
      }
    }
  };

  const getPosition = (index: number) => {
    const diff = index - currentIndex;
    if (diff === 0) return 'center';
    if (diff === 1 || (diff === -videos.length + 1)) return isMobile ? 'bottom' : 'right';
    if (diff === -1 || (diff === videos.length - 1)) return isMobile ? 'top' : 'left';
    return 'hidden';
  };

  const handleVideoClick = (index: number) => {
    const position = getPosition(index);
    if (position === 'top' || position === 'left') {
      goToPrevious();
    } else if (position === 'bottom' || position === 'right') {
      goToNext();
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: isMobile ? '600px' : '450px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        perspective: '300px',
      }}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      role="region"
      aria-label="Video carousel"
    >
      {videos.map((video, index) => {
        const position = getPosition(index);
        const isCenter = position === 'center';
        
        return (
          <Box
            key={index}
            onClick={() => !isCenter && handleVideoClick(index)}
            sx={{
              position: 'absolute',
              width: { xs: '280px', sm: '480px', md: '600px' },
              height: { xs: '157.5px', sm: '270px', md: '337.5px' },
              transition: 'all 0.5s ease',
              cursor: isCenter ? 'default' : 'pointer',
              ...(position === 'center' && {
                transform: 'translateX(0) translateY(0) scale(1.05)',
                zIndex: 3,
                opacity: 1,
              }),
              ...(position === 'left' && {
                transform: 'translateX(-60%) scale(0.9) rotateY(8deg)',
                zIndex: 2,
                opacity: 0.8,
              }),
              ...(position === 'right' && {
                transform: 'translateX(60%) scale(0.9) rotateY(-8deg)',
                zIndex: 2,
                opacity: 0.8,
              }),
              ...(position === 'top' && {
                transform: 'translateY(-60%) scale(0.9) rotateX(-8deg)',
                zIndex: 2,
                opacity: 0.8,
              }),
              ...(position === 'bottom' && {
                transform: 'translateY(60%) scale(0.9) rotateX(8deg)',
                zIndex: 2,
                opacity: 0.8,
              }),
              ...(position === 'hidden' && {
                transform: 'translateX(0) scale(0)',
                zIndex: 1,
                opacity: 0,
                pointerEvents: 'none',
              }),
            }}
          >
            {isCenter ? (
              <Box
                component="iframe"
                src={video.url}
                title={video.title}
                sx={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  borderRadius: '8px',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.3)',
                }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ) : (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: '#000',
                  borderRadius: '8px',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.3)',
                  backgroundImage: `url(https://img.youtube.com/vi/${video.url.split('/').pop()}/hqdefault.jpg)`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            )}
          </Box>
        );
      })}

      {isMobile ? (
        <>
          <IconButton
            onClick={goToPrevious}
            sx={{
              position: 'absolute',
              top: '10%',
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.7)',
              },
            }}
          >
            <KeyboardArrowUpIcon />
          </IconButton>

          <IconButton
            onClick={goToNext}
            sx={{
              position: 'absolute',
              bottom: '10%',
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.7)',
              },
            }}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </>
      ) : (
        <>
          <IconButton
            onClick={goToPrevious}
            sx={{
              position: 'absolute',
              left: { xs: '5%', sm: '15%' },
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.7)',
              },
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>

          <IconButton
            onClick={goToNext}
            sx={{
              position: 'absolute',
              right: { xs: '5%', sm: '15%' },
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.7)',
              },
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default VideoCarousel3D;
