import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Icon,
  Box,
  ButtonGroup,
  Grid
} from '@mui/material';
import MuiContainer from '@mui/material/Container';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTranslation } from 'react-i18next';
import ContentEditor from '../../components/admin/ContentEditor/ContentEditor';
import { ContentBlock, ContentBlockType } from '../../components/admin/ContentEditor/types';
import { ref, push, update, onValue } from 'firebase/database';
import { db } from '../../firebase/config';
import { uploadToS3 } from '../../utils/s3Upload';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { FaMagic } from 'react-icons/fa';
import { MdTranslate } from 'react-icons/md';
import { translateText } from '../../utils/openai';
import { generateDescription } from '../../utils/openai';

export interface TranslatedContent {
  en: string;
  fr: string;
}

interface NewsItem {
  id: string;
  title: TranslatedContent;
  description: TranslatedContent;
  content: Array<{
    type: ContentBlockType;
    value: TranslatedContent | string; // string for image URLs
  }>;
  imageUrl: string;
  category: string;
  expirationDate: string;
  createdAt: string;
}

const Container = styled(MuiContainer)(({ theme }) => ({
  maxWidth: '1200px',
  margin: '0 auto',
}));

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2rem',
  '& h1': {
    color: '#5ba85b',
    textShadow: '0 0 10px rgba(91, 168, 91, 0.3)',
  },
});

const NewsCard = styled(Card)({
  backgroundColor: '#383838',
  color: '#ffffff',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 8px rgba(91, 168, 91, 0.2)',
  },
});

const NewsImage = styled(CardMedia)({
  height: 200,
});

const NewsContent = styled(CardContent)({
  position: 'relative',
  '& .MuiTypography-root': {
    color: '#ffffff',
  },
});

const ActionButtons = styled('div')({
  position: 'absolute',
  top: '1rem',
  right: '1rem',
  display: 'flex',
  gap: '0.5rem',
});

type TranslationStatus = 'idle' | 'translating' | 'untranslated' | 'saved';

const AdminNews: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState<NewsItem[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<NewsItem | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [editorContent, setEditorContent] = useState<ContentBlock[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [editorLanguage, setEditorLanguage] = useState<'en' | 'fr'>('en');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [translationStatus, setTranslationStatus] = useState<TranslationStatus>('idle');
  const [modifiedFields, setModifiedFields] = useState<Set<string>>(new Set());
  const [isGeneratingDescription, setIsGeneratingDescription] = useState(false);

  const handleLanguageChange = (lng: 'en' | 'fr') => {
    setEditorLanguage(lng);
    if (currentItem) {
      setTitle(currentItem.title[lng] || '');
      setDescription(currentItem.description[lng] || '');

      // Convert stored content format to ContentBlock format for the new language
      let contentBlocks: ContentBlock[] = [];
      try {
        contentBlocks = currentItem.content.map(block => {
          const baseBlock = {
            id: Math.random().toString(),
            type: block.type,
            order: 0
          };

          if (block.type === 'image') {
            return {
              ...baseBlock,
              type: 'image',
              url: typeof block.value === 'string' ? block.value : '',
              width: 0,
              height: 0,
              alt: ''
            };
          }

          const translatedValue = typeof block.value === 'object' ? block.value[lng] || '' : '';

          if (block.type === 'title') {
            return {
              ...baseBlock,
              type: 'title',
              content: translatedValue,
              fontSize: 24,
              fontWeight: 600
            };
          }

          return {
            ...baseBlock,
            type: 'paragraph',
            content: translatedValue
          };
        });
      } catch (error) {
        console.error('Error converting content:', error);
      }

      setEditorContent(contentBlocks);
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    setModifiedFields(prev => new Set(Array.from(prev).concat(['title'])));
    setTranslationStatus('untranslated');
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
    setModifiedFields(prev => new Set(Array.from(prev).concat(['description'])));
    setTranslationStatus('untranslated');
  };

  const handleContentChange = (newContent: ContentBlock[]) => {
    // Check if this is just a reordering by comparing content values
    const isReorderOnly = newContent.length === editorContent.length && 
      newContent.every(newBlock => {
        const oldBlock = editorContent.find(b => b.id === newBlock.id);
        if (!oldBlock) return false;
        
        // For text blocks, compare content
        if ('content' in newBlock && 'content' in oldBlock) {
          return newBlock.content === oldBlock.content;
        }
        // For image blocks, compare url
        if ('url' in newBlock && 'url' in oldBlock) {
          return newBlock.url === oldBlock.url;
        }
        return true;
      });

    setEditorContent(newContent);
    
    // Only trigger translation status if text content was modified (not just reordered)
    if (!isReorderOnly) {
      const hasTextChanges = newContent.some((block, index) => {
        const oldBlock = editorContent.find(b => b.id === block.id);
        return (
          (block.type === 'title' || block.type === 'paragraph') &&
          'content' in block && 
          oldBlock && 
          'content' in oldBlock &&
          block.content !== oldBlock.content
        );
      });

      if (hasTextChanges) {
        setModifiedFields(prev => new Set(Array.from(prev).concat(['content'])));
        setTranslationStatus('untranslated');
      }
    }
  };

  const handleImageSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPreviewUrl(URL.createObjectURL(selectedFile));
    }
  };

  const handleContentImageSelect = async (blockId: string, file: File) => {
    try {
      const imageUrl = await uploadToS3(file);
      const newContent = editorContent.map(block =>
        block.id === blockId
          ? { ...block, url: imageUrl }
          : block
      );
      setEditorContent(newContent);
    } catch (error) {
      console.error('Error uploading image:', error);
      alert(t('admin.news.errorUploadingImage'));
    }
  };

  const getTranslationStatusColor = () => {
    switch (translationStatus) {
      case 'translating':
        return '#ffa726'; // Orange
      case 'untranslated':
        return '#ef5350'; // Red
      default:
        return '#757575'; // Grey
    }
  };

  const getTranslationStatusText = () => {
    switch (translationStatus) {
      case 'translating':
        return 'Translation occurring...';
      case 'untranslated':
        return 'Untranslated changes';
      default:
        return 'No changes to care about';
    }
  };

  const handleSave = async () => {
    try {
      if (!currentItem && !file) {
        alert(t('admin.news.imageRequired'));
        return;
      }

      let imageUrl = currentItem?.imageUrl || '';
      if (file) {
        imageUrl = await uploadToS3(file);
      }

      if (!title || !description) {
        alert(t('admin.news.allFieldsRequired'));
        return;
      }

      setTranslationStatus('translating');

      // Prepare translations for modified fields
      const targetLang = editorLanguage === 'en' ? 'fr' : 'en';
      
      // Initialize with current content or empty structure
      const translations: Partial<NewsItem> = {
        title: {
          ...currentItem?.title || { en: '', fr: '' },
          [editorLanguage]: title
        },
        description: {
          ...currentItem?.description || { en: '', fr: '' },
          [editorLanguage]: description
        }
      };

      // Handle content separately to ensure type safety
      const newContent: Array<{type: ContentBlockType; value: TranslatedContent | string}> = editorContent.map(block => {
        const existingBlock = currentItem?.content?.find(c => c.type === block.type);
        
        if (block.type === 'image' && 'url' in block) {
          return {
            type: block.type,
            value: block.url
          };
        }

        const currentValue = 'content' in block ? block.content : '';
        const existingValue = typeof existingBlock?.value === 'object' ? existingBlock.value : { en: '', fr: '' };

        return {
          type: block.type,
          value: {
            ...existingValue,
            [editorLanguage]: currentValue
          }
        };
      });

      translations.content = newContent;

      // Translate modified fields
      if (modifiedFields.has('title') && translations.title) {
        translations.title[targetLang] = await translateText(title, targetLang);
      }

      if (modifiedFields.has('description') && translations.description) {
        translations.description[targetLang] = await translateText(description, targetLang);
      }

      if (modifiedFields.has('content') && translations.content) {
        translations.content = await Promise.all(
          translations.content.map(async block => {
            if (block.type !== 'image' && typeof block.value === 'object') {
              const content = block.value[editorLanguage];
              if (content) {
                return {
                  type: block.type,
                  value: {
                    ...block.value,
                    [targetLang]: await translateText(content, targetLang)
                  }
                };
              }
            }
            return block;
          })
        );
      }

      const newsData: Partial<NewsItem> = {
        ...translations,
        imageUrl,
        category: selectedCategory,
        expirationDate: (document.querySelector<HTMLInputElement>('input[name="expirationDate"]')?.value || ''),
        createdAt: new Date().toISOString()
      };

      if (!currentItem) {
        await push(ref(db, 'news'), newsData);
      } else {
        await update(ref(db, `news/${currentItem.id}`), newsData);
      }

      setTranslationStatus('saved');
      setTimeout(() => {
        setIsDialogOpen(false);
        setTranslationStatus('idle');
      }, 1500);
    } catch (error) {
      console.error('Error saving news:', error);
      alert(t('admin.news.errorSaving'));
      setTranslationStatus('untranslated');
    }
  };

  const handleEdit = (item: NewsItem) => {
    setCurrentItem(item);
    setSelectedCategory(item.category || '');
    setTitle(item.title[editorLanguage] || '');
    setDescription(item.description[editorLanguage] || '');
    setPreviewUrl(item.imageUrl);
    setIsDialogOpen(true);

    // Convert stored content format to ContentBlock format
    let contentBlocks: ContentBlock[] = [];
    try {
      contentBlocks = item.content.map((block, index) => {
        const baseBlock = {
          id: `block-${index}-${Date.now()}`,
          type: block.type,
          order: index,
        };

        if (block.type === 'image') {
          return {
            ...baseBlock,
            type: 'image',
            url: typeof block.value === 'string' ? block.value : '',
            width: 800,
            height: 400,
            alt: ''
          };
        }

        const translatedValue = typeof block.value === 'object' ? block.value[editorLanguage] || '' : '';

        if (block.type === 'title') {
          return {
            ...baseBlock,
            type: 'title',
            content: translatedValue,
            fontSize: 24,
            fontWeight: 600
          };
        }

        return {
          ...baseBlock,
          type: 'paragraph',
          content: translatedValue
        };
      });
    } catch (error) {
      console.error('Error converting content:', error);
    }

    setEditorContent(contentBlocks);
  };

  const handleAdd = () => {
    setCurrentItem(null);
    setEditorContent([]);
    setFile(null);
    setPreviewUrl('');
    setTitle('');
    setDescription('');
    setIsDialogOpen(true);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this news item?')) {
      try {
        await update(ref(db), {
          [`/news/${id}`]: null
        });
      } catch (error) {
        console.error('Error deleting news:', error);
      }
    }
  };

  const handleTranslateModified = async () => {
    try {
      setTranslationStatus('translating');
      const targetLang = editorLanguage === 'en' ? 'fr' : 'en';

      if (modifiedFields.has('title')) {
        const translatedTitle = await translateText(title, targetLang);
        setCurrentItem(prev => prev ? {
          ...prev,
          title: {
            ...prev.title,
            [targetLang]: translatedTitle
          }
        } : null);
      }

      if (modifiedFields.has('description')) {
        const translatedDesc = await translateText(description, targetLang);
        setCurrentItem(prev => prev ? {
          ...prev,
          description: {
            ...prev.description,
            [targetLang]: translatedDesc
          }
        } : null);
      }

      if (modifiedFields.has('content')) {
        const translatedContent = await Promise.all(
          editorContent.map(async block => {
            if (block.type !== 'image' && 'content' in block) {
              const translatedText = await translateText(block.content, targetLang);
              return {
                ...block,
                content: translatedText
              };
            }
            return block;
          })
        );
        setEditorContent(translatedContent);
      }

      setModifiedFields(new Set());
      setTranslationStatus('idle');
    } catch (error) {
      console.error('Translation error:', error);
      setTranslationStatus('untranslated');
    }
  };

  const handleGenerate = async () => {
    try {
      setIsGeneratingDescription(true);

      // Collect all text content
      const textContent = [
        title,
        ...editorContent
          .filter(block => block.type !== 'image' && 'content' in block)
          .map(block => ('content' in block ? block.content : ''))
      ].join('\n\n');

      if (!textContent.trim()) {
        alert('Please add some content before generating a description');
        return;
      }

      // Generate description
      const generatedDescription = await generateDescription(textContent, 200);

      // Update description field
      setDescription(generatedDescription);

      // Mark description as modified for translation
      setModifiedFields(prev => new Set(Array.from(prev).concat(['description'])));
      setTranslationStatus('untranslated');
    } catch (error) {
      console.error('Error generating description:', error);
      alert('Failed to generate description. Please try again.');
    } finally {
      setIsGeneratingDescription(false);
    }
  };

  useEffect(() => {
    const newsRef = ref(db, 'news');
    // Set up listener for news data
    const unsubscribe = onValue(newsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Convert object to array and sort by createdAt
        const newsArray = Object.entries(data).map(([key, value]: [string, any]) => ({
          ...value,
          id: key
        }));
        newsArray.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        setItems(newsArray);
      } else {
        setItems([]);
      }
    });

    // Cleanup subscription
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Reset translation status when dialog opens
    if (isDialogOpen) {
      setTranslationStatus('idle');
      setModifiedFields(new Set());
    }
  }, [isDialogOpen]);

  const getEmptyTranslatedContent = (): TranslatedContent => ({
    en: '',
    fr: ''
  });

  return (
    <Container>
      <Header>
        <Typography variant="h4" component="h1">
          {t('admin.news.title')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAdd}
          sx={{
            backgroundColor: '#5ba85b',
            '&:hover': { backgroundColor: '#4c8f4c' },
          }}
        >
          {t('admin.news.add')}
        </Button>
      </Header>

      <Grid container spacing={3}>
        {items.map((item) => (
          <Grid item xs={12} md={6} key={item.id}>
            <NewsCard>
              <NewsImage
                image={item.imageUrl}
                title={item.title.en}
              />
              <NewsContent>
                <ActionButtons>
                  <IconButton onClick={() => handleEdit(item)} size="small" sx={{ color: '#5ba85b' }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    sx={{ color: '#ff4444' }}
                    onClick={() => handleDelete(item.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ActionButtons>
                <h2>{item.title[editorLanguage]}</h2>
                <p>{item.description[editorLanguage]}</p>
                <small>{new Date(item.expirationDate).toLocaleDateString()}</small>
              </NewsContent>
            </NewsCard>
          </Grid>
        ))}
      </Grid>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
          <span>{currentItem ? t('admin.news.edit') : t('admin.news.add')}</span>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1, justifyContent: 'flex-end' }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              flex: 1,
              maxWidth: '300px',
              justifyContent: 'flex-end'
            }}>
              <Typography
                variant="body2"
                sx={{
                  color: getTranslationStatusColor(),
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  fontWeight: 500
                }}
              >
                {getTranslationStatusText()}
              </Typography>
              {translationStatus === 'untranslated' && (
                <IconButton
                  onClick={handleTranslateModified}
                  size="small"
                  sx={{
                    backgroundColor: '#1976d2',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#1565c0',
                    },
                    width: 32,
                    height: 32,
                    minWidth: 32
                  }}
                >
                  <MdTranslate size={16} />
                </IconButton>
              )}
            </Box>
            <ButtonGroup variant="contained" size="small">
              <Button
                onClick={() => handleLanguageChange('fr')}
                sx={{
                  backgroundColor: editorLanguage === 'fr' ? '#1976d2' : 'rgba(0, 0, 0, 0.1)',
                  color: editorLanguage === 'fr' ? 'white' : 'inherit',
                  '&:hover': {
                    backgroundColor: editorLanguage === 'fr' ? '#1565c0' : 'rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                FR
              </Button>
              <Button
                onClick={() => handleLanguageChange('en')}
                sx={{
                  backgroundColor: editorLanguage === 'en' ? '#1976d2' : 'rgba(0, 0, 0, 0.1)',
                  color: editorLanguage === 'en' ? 'white' : 'inherit',
                  '&:hover': {
                    backgroundColor: editorLanguage === 'en' ? '#1565c0' : 'rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                EN
              </Button>
            </ButtonGroup>
          </Box>
        </DialogTitle>
        <DialogContent>
          <form>
            <TextField
              margin="normal"
              name="title"
              label={t('admin.news.titleField')}
              type="text"
              fullWidth
              value={title}
              onChange={handleTitleChange}
              required
            />
            <TextField
              margin="normal"
              name="expirationDate"
              label={t('admin.news.expirationDateField')}
              type="date"
              fullWidth
              defaultValue={currentItem?.expirationDate ? new Date(currentItem.expirationDate).toISOString().split('T')[0] : new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]}
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
            <Box sx={{ position: 'relative', width: '100%', marginBottom: 2 }}>
              {previewUrl && (
                <>
                  <img
                    src={previewUrl}
                    alt="Preview"
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxHeight: '300px',
                      objectFit: 'contain'
                    }}
                  />
                  <Button
                    component="label"
                    variant="contained"
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      backgroundColor: 'rgba(0, 0, 0, 0.6)',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      }
                    }}
                  >
                    Modify Image
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleImageSelect}
                    />
                  </Button>
                </>
              )}
              {!previewUrl && (
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  sx={{ width: '100%', height: '200px' }}
                >
                  SELECT IMAGE
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleImageSelect}
                  />
                </Button>
              )}
            </Box>
            <TextField
              margin="normal"
              name="description"
              label={t('admin.news.descriptionField')}
              multiline
              rows={2}
              fullWidth
              value={description}
              onChange={handleDescriptionChange}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      onClick={handleGenerate}
                      variant="contained"
                      disabled={isGeneratingDescription}
                      sx={{
                        backgroundColor: '#5ba85b',
                        '&:hover': {
                          backgroundColor: '#4a8f4a',
                        },
                        '&.Mui-disabled': {
                          backgroundColor: '#8fb98f',
                          color: 'white'
                        }
                      }}
                    >
                      <FaMagic style={{ marginRight: '8px' }} />
                      {isGeneratingDescription ? 'Generating...' : 'AI'}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                labelId="category-label"
                name="category"
                label="Category"
                defaultValue={currentItem?.category || ''}
              >
                <MenuItem value="event">Event</MenuItem>
                <MenuItem value="meeting">Meeting</MenuItem>
                <MenuItem value="program">Program</MenuItem>
              </Select>
            </FormControl>

            <div style={{ marginTop: '1rem' }}>
              <ContentEditor
                content={editorContent}
                onChange={handleContentChange}
                onImageSelect={handleContentImageSelect}
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>{t('common.cancel')}</Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminNews;
