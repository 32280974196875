import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Box, Button, useTheme, useMediaQuery } from '@mui/material';
import Timeline from '../components/Timeline';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';
import ReactFullpage from '@fullpage/react-fullpage';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import VisionCarousel from '../components/VisionCarousel/VisionCarousel';
import LatestNews from '../components/LatestNews/LatestNews';
import ProgramCarousel from '../components/ProgramCarousel/ProgramCarousel';
import { programCards } from '../data/programCards';
import VideoCarousel3D from '../components/VideoCarousel3D/VideoCarousel3D';

import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { IconButton, styled } from '@mui/material';

const SocialMediaContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '1rem',
  right: '1rem',
  display: 'flex',
  gap: theme.spacing(1),
  zIndex: 1000,
}));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  padding: theme.spacing(1),
  '&:hover': {
    color: theme.palette.primary.dark,
    transform: 'scale(1.1)',
    transition: 'transform 0.2s ease-in-out',
  },
}));

const Home: React.FC = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const img = new Image();
    img.src = isMobile
      ? `${process.env.PUBLIC_URL}/images/cabral-phone.png`
      : `${process.env.PUBLIC_URL}/images/cabral-libii.png`;
    img.onload = () => {
      setImageLoaded(true);
    };

    // Cleanup function to reset fullpage.js settings
    return () => {
      document.documentElement.style.overflow = '';
      document.body.style.overflow = '';
      document.documentElement.style.height = '';
      document.body.style.height = '';
      document.documentElement.style.position = '';
      document.body.style.position = '';
    };
  }, []);

  return (
    <Container maxWidth={false} disableGutters sx={{ position: 'relative' }}>
      <SocialMediaContainer>
        <a
          href="https://www.facebook.com/groups/160719334835343"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        >
          <SocialIconButton>
            <FacebookIcon />
          </SocialIconButton>
        </a>

        <a
          href="https://www.youtube.com/@pcrnofficiel93"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="YouTube"
        >
          <SocialIconButton>
            <YouTubeIcon />
          </SocialIconButton>
        </a>

        <a
          href="https://www.instagram.com/pcrn_officiel/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
        >
          <SocialIconButton>
            <InstagramIcon />
          </SocialIconButton>
        </a>

        <a
          href="https://x.com/Pcrn_Officiel"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Twitter"
        >
          <SocialIconButton>
            <TwitterIcon />
          </SocialIconButton>
        </a>
      </SocialMediaContainer>

      <ReactFullpage
        scrollingSpeed={1000}
        navigation={true}
        credits={{ enabled: false }}
        render={({ state, fullpageApi }) => (
          <div id="fullpage-wrapper">
            <div className="section">
              <Box
                sx={{
                  position: 'relative',
                  height: '100vh',
                  overflow: 'hidden',
                  backgroundColor: 'black',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/${isMobile ? 'cabral-phone.png' : 'cabral-libii.png'})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: imageLoaded ? 0.7 : 0,
                    transition: 'opacity 2s ease-in-out',
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    zIndex: 1,
                    pointerEvents: 'none',
                  }
                }}
                className={imageLoaded ? 'image-loaded' : ''}
              >
                <motion.div
                  initial={{ scale: 1.5 }}
                  animate={{ scale: 1 }}
                  transition={{
                    duration: 3,
                    ease: [0.645, 0.045, 0.355, 1],
                  }}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                  }}
                >
                  <Container
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      position: 'relative',
                      zIndex: 2,

                    }}
                  >
                    <motion.div
                      initial={{ y: 50, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 1, delay: 0.5, ease: "easeOut" }}
                    >
                      <Typography
                        variant="h1"
                        sx={{
                          color: 'white',
                          textAlign: 'center',
                          fontSize: { xs: '2.5rem', md: '4rem' },
                          fontWeight: 700,
                          textShadow: `
                          2px 2px 2px rgba(0, 0, 0, 0.9), 
                          -1px -1px 2px rgba(0, 0, 0, 0.9)
                        `, mb: 2,
                        }}
                      >
                        {t('home.mainTitle')}
                      </Typography>
                    </motion.div>

                    <motion.div
                      initial={{ y: 50, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 1, delay: 0.7, ease: "easeOut" }}
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          color: 'white',
                          textAlign: 'center',
                          fontSize: { xs: '1.5rem', md: '2rem' },
                          fontWeight: 500,
                          textShadow: `
                          2px 2px 2px rgba(0, 0, 0, 0.9), 
                          -1px -1px 2px rgba(0, 0, 0, 0.9)
                        `,
                          mb: 3.75,
                        }}
                      >
                        {t('home.subtitle')}
                      </Typography>
                    </motion.div>

                    <motion.div
                      initial={{ y: 50, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 1, delay: 0.9, ease: "easeOut" }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: isMobile ? 'column' : 'row',
                          gap: 2,
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'relative',
                          zIndex: 2,
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={() => navigate('/donate')}
                          sx={{
                            px: 4,
                            py: 1.5,
                            fontSize: '1.1rem',
                            fontWeight: 600,
                            borderRadius: 2,
                            position: 'relative',
                            zIndex: 2
                          }}
                        >
                          {t('home.donateButton')}
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={() => navigate('/scrutateurs')}
                          sx={{
                            px: 4,
                            py: 1.5,
                            fontSize: '1.1rem',
                            fontWeight: 600,
                            borderRadius: 2,
                            borderColor: 'white',
                            color: 'white',
                            backgroundColor: '#ff0000',
                            position: 'relative',
                            textShadow: `
                          2px 2px 2px rgba(0, 0, 0, 0.9), 
                          -1px -1px 2px rgba(0, 0, 0, 0.9)
                        `,
                            zIndex: 2,
                            '&:hover': {
                              borderColor: 'white',
                              backgroundColor: '#cc0000',
                            },
                          }}
                        >
                          {t('home.adhereButton')}
                        </Button>
                      </Box>
                    </motion.div>
                  </Container>
                </motion.div>
              </Box>
            </div>

            <div className="section">
              <Box
                sx={{
                  minHeight: '100vh',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e7eb 100%)',
                  py: 4
                }}
              >
                <Container maxWidth="lg">
                  <motion.div
                    initial={{ y: 30, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: true }}
                  >
                    <Typography
                      variant="h2"
                      align="center"
                      gutterBottom
                      sx={{
                        fontWeight: 700,
                        mb: 6,
                        color: '#333',
                      }}
                    >
                      {t('vision.title')}
                    </Typography>
                  </motion.div>

                  <motion.div
                    initial={{ y: 30, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    viewport={{ once: true }}
                  >
                    <ProgramCarousel cards={programCards.slice(0, 6)} itemsPerPage={3} />
                  </motion.div>

                  <motion.div
                    initial={{ y: 30, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                    viewport={{ once: true }}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '2rem'
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => navigate('/program')}
                      sx={{
                        px: 4,
                        py: 1.5,
                        fontSize: '1.1rem',
                        fontWeight: 600,
                        borderRadius: 2,
                      }}
                    >
                      {t('home.moreProgramButton')}
                    </Button>
                  </motion.div>
                </Container>
              </Box>
            </div>

            {/* News Section */}
            <div className="section">
              <Box
                sx={{
                  minHeight: '100vh',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  background: '#ffffff',
                  py: 4
                }}
              >
                <LatestNews />
              </Box>
            </div>

            {/* Biography Section */}
            <div className="section">
              <Box sx={{
                background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e7eb 100%)',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
                <Container>
                  <Typography variant="h3" align="center" gutterBottom sx={{ mb: 6 }}>
                    Biographie
                  </Typography>
                  <Typography
                    variant={isMobile ? "body1" : "h6"}
                    align="center"
                    color="common.white"
                    sx={{
                      position: 'relative',
                      zIndex: 2,
                      maxWidth: '800px',
                      mx: 'auto',
                      mb: 4,
                      fontSize: isMobile ? '0.9rem' : '1.25rem',
                      px: isMobile ? 2 : 0,
                    }}
                  >
                    <Timeline />
                  </Typography>
                </Container>
              </Box>
            </div>

            <div className="section">
              <Box sx={{
                background: 'linear-gradient(135deg, #2d3748 0%, #1a202c 100%)',
                minHeight: '100vh',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                pt: 2,
              }}>
                <Container maxWidth="lg" sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 'calc(100vh - 140px)', // Reserve space for footer
                }}>
                  <VideoCarousel3D
                    videos={[
                      {
                        url: 'https://www.youtube.com/embed/Wmnxq4VrKns',
                        title: 'PCRN Video 1'
                      },
                      {
                        url: 'https://www.youtube.com/embed/Wmnxq4VrKns',
                        title: 'PCRN Video 2'
                      },
                      {
                        url: 'https://www.youtube.com/embed/Wmnxq4VrKns',
                        title: 'PCRN Video 3'
                      }
                    ]}
                  />
                </Container>
                <Box sx={{
                  width: '100%',
                  position: 'relative',
                  bottom: 0,
                  backgroundColor: 'inherit'
                }}>
                  <Footer />
                </Box>
              </Box>
            </div>
          </div>
        )}
      />
    </Container>
  );
};

export default Home;
