import { createTheme } from '@mui/material/styles';
import { colors } from './styles/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.green.main,
      light: colors.green.light,
      dark: colors.green.dark,
      contrastText: colors.yellow.main,
    },
    secondary: {
      main: colors.red.main,
      light: colors.red.light,
      dark: colors.red.dark,
      contrastText: colors.yellow.main,
    },
    warning: {
      main: colors.yellow.main,
      light: colors.yellow.light,
      dark: colors.yellow.dark,
    },
    background: {
      default: colors.background.default,
      paper: colors.background.paper,
    },
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          background: `linear-gradient(45deg, ${colors.green.main} 30%, ${colors.red.main} 90%)`,
          color: colors.yellow.main,
          boxShadow: '0 3px 5px 2px rgba(0, 122, 61, .3)',
          '&:hover': {
            background: `linear-gradient(45deg, ${colors.green.dark} 30%, ${colors.red.dark} 90%)`,
            color: colors.yellow.light,
          },
        },
        outlined: {
          borderColor: colors.green.main,
          color: colors.green.main,
          '&:hover': {
            borderColor: colors.red.main,
            color: colors.red.main,
          },
        },
        containedPrimary: {
          color: colors.yellow.main,
          '&:hover': {
            color: colors.yellow.light,
          },
        },
        containedSecondary: {
          color: colors.yellow.main,
          '&:hover': {
            color: colors.yellow.light,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: colors.background.paper,
          '&:hover': {
            boxShadow: `0 8px 16px rgba(0, 122, 61, 0.2)`,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.green.main,
          color: colors.text.light,
        },
      },
    },
  },
});

export default theme;
