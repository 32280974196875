import { OpenAI } from 'openai';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export async function translateText(text: string, targetLanguage: 'en' | 'fr'): Promise<string> {
  try {
    const prompt = `Translate the following text to ${targetLanguage === 'en' ? 'English' : 'French'}:\n\n${text}`;

    const completion = await openai.chat.completions.create({
      messages: [{ role: "user", content: prompt }],
      model: "gpt-4o-mini",
    });

    return completion.choices[0]?.message?.content || text;
  } catch (error) {
    console.error('Translation error:', error);
    return text;
  }
}

export async function generateDescription(text: string, maxLength: number = 200): Promise<string> {
  try {
    const prompt = `Generate a concise description (maximum ${maxLength} characters) that summarizes the following text:\n\n${text}`;

    const completion = await openai.chat.completions.create({
      messages: [{ role: "user", content: prompt }],
      model: "gpt-4o-mini",
    });

    return completion.choices[0]?.message?.content || '';
  } catch (error) {
    console.error('Description generation error:', error);
    return '';
  }
}
