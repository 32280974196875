import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Area } from '@ant-design/plots';

const Container = styled('div')({
  padding: '1.5rem',
});

const StatsCard = styled(Card)(({ color }: { color: string }) => ({
  backgroundColor: color,
  color: '#ffffff',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
  },
  transition: 'all 0.3s ease',
}));

const IconWrapper = styled('div')({
  position: 'absolute',
  right: '1rem',
  top: '1rem',
  opacity: 0.3,
  '& svg': {
    fontSize: '3rem',
  },
});

const StatValue = styled(Typography)({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  marginBottom: '0.5rem',
});

const StatLabel = styled(Typography)({
  fontSize: '1rem',
  opacity: 0.9,
});

const ChartCard = styled(Card)({
  backgroundColor: '#ffffff',
  marginTop: '2rem',
  '& .chart-header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    borderBottom: '1px solid #eee',
  },
});

const AdminHome: React.FC = () => {
  // Sample data for the area chart
  const data = [
    { month: 'January', sales: 30, visitors: 50 },
    { month: 'February', sales: 45, visitors: 65 },
    { month: 'March', sales: 35, visitors: 55 },
    { month: 'April', sales: 50, visitors: 70 },
    { month: 'May', sales: 40, visitors: 60 },
    { month: 'June', sales: 55, visitors: 75 },
    { month: 'July', sales: 60, visitors: 80 },
  ];

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard color="#26A69A">
            <CardContent>
              <IconWrapper>
                <ShoppingBagIcon />
              </IconWrapper>
              <StatValue variant="h3">150</StatValue>
              <StatLabel variant="subtitle1">New Orders</StatLabel>
            </CardContent>
          </StatsCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard color="#66BB6A">
            <CardContent>
              <IconWrapper>
                <TrendingUpIcon />
              </IconWrapper>
              <StatValue variant="h3">53%</StatValue>
              <StatLabel variant="subtitle1">Bounce Rate</StatLabel>
            </CardContent>
          </StatsCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard color="#FFA726">
            <CardContent>
              <IconWrapper>
                <PersonAddIcon />
              </IconWrapper>
              <StatValue variant="h3">44</StatValue>
              <StatLabel variant="subtitle1">User Registrations</StatLabel>
            </CardContent>
          </StatsCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard color="#EF5350">
            <CardContent>
              <IconWrapper>
                <VisibilityIcon />
              </IconWrapper>
              <StatValue variant="h3">65</StatValue>
              <StatLabel variant="subtitle1">Unique Visitors</StatLabel>
            </CardContent>
          </StatsCard>
        </Grid>
      </Grid>

      <ChartCard>
        <div className="chart-header">
          <Typography variant="h6">Sales Overview</Typography>
        </div>
        <CardContent>
          <div style={{ height: '400px' }}>

          </div>
        </CardContent>
      </ChartCard>
    </Container>
  );
};

export default AdminHome;
