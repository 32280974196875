import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

interface Donation {
  id: string;
  donorName: string;
  email: string;
  amount: number;
  date: string;
  message?: string;
  isAnonymous: boolean;
}

interface DonationStats {
  totalRaised: number;
  goalAmount: number;
  totalDonors: number;
  averageDonation: number;
}

const Container = styled('div')({
  maxWidth: '1200px',
  margin: '0 auto',
});

const Header = styled('div')({
  marginBottom: '2rem',
  '& h1': {
    color: '#5ba85b',
    textShadow: '0 0 10px rgba(91, 168, 91, 0.3)',
  },
});

const StatCard = styled(Card)({
  backgroundColor: '#383838',
  color: '#ffffff',
  height: '100%',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(91, 168, 91, 0.2)',
  },
});

const IconWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  marginBottom: '1rem',
  '& svg': {
    fontSize: '2rem',
    color: '#5ba85b',
  },
});

const ProgressWrapper = styled('div')({
  marginTop: '1rem',
  '& .MuiLinearProgress-root': {
    backgroundColor: 'rgba(91, 168, 91, 0.2)',
    borderRadius: '4px',
    height: '8px',
  },
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#5ba85b',
  },
});

const AdminDonations: React.FC = () => {
  const [stats, setStats] = useState<DonationStats>({
    totalRaised: 75000,
    goalAmount: 100000,
    totalDonors: 150,
    averageDonation: 500,
  });

  const [donations] = useState<Donation[]>([
    {
      id: '1',
      donorName: 'Jane Smith',
      email: 'jane@example.com',
      amount: 1000,
      date: '2025-02-01',
      message: 'Keep up the great work!',
      isAnonymous: false,
    },
    // Add more sample donations
  ]);

  const [isGoalDialogOpen, setIsGoalDialogOpen] = useState(false);
  const [newGoal, setNewGoal] = useState(stats.goalAmount);

  const handleUpdateGoal = () => {
    setStats({ ...stats, goalAmount: newGoal });
    setIsGoalDialogOpen(false);
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
  };

  return (
    <Container>
      <Header>
        <h1>Donations Management</h1>
      </Header>

      <Grid container spacing={3} sx={{ marginBottom: '2rem' }}>
        <Grid item xs={12} md={4}>
          <StatCard>
            <CardContent>
              <IconWrapper>
                <AccountBalanceWalletIcon />
                <div>
                  <Typography variant="h6">Total Raised</Typography>
                  <Typography variant="h4">{formatCurrency(stats.totalRaised)}</Typography>
                </div>
              </IconWrapper>
              <ProgressWrapper>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                  <Typography variant="body2">Progress</Typography>
                  <Typography variant="body2">
                    {Math.round((stats.totalRaised / stats.goalAmount) * 100)}%
                  </Typography>
                </div>
                <LinearProgress
                  variant="determinate"
                  value={(stats.totalRaised / stats.goalAmount) * 100}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.5rem' }}>
                  <Typography variant="body2">Goal: {formatCurrency(stats.goalAmount)}</Typography>
                  <Button
                    size="small"
                    onClick={() => setIsGoalDialogOpen(true)}
                    sx={{ color: '#5ba85b' }}
                  >
                    Update Goal
                  </Button>
                </div>
              </ProgressWrapper>
            </CardContent>
          </StatCard>
        </Grid>

        <Grid item xs={12} md={4}>
          <StatCard>
            <CardContent>
              <IconWrapper>
                <PeopleIcon />
                <div>
                  <Typography variant="h6">Total Donors</Typography>
                  <Typography variant="h4">{stats.totalDonors}</Typography>
                </div>
              </IconWrapper>
            </CardContent>
          </StatCard>
        </Grid>

        <Grid item xs={12} md={4}>
          <StatCard>
            <CardContent>
              <IconWrapper>
                <TrendingUpIcon />
                <div>
                  <Typography variant="h6">Average Donation</Typography>
                  <Typography variant="h4">{formatCurrency(stats.averageDonation)}</Typography>
                </div>
              </IconWrapper>
            </CardContent>
          </StatCard>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ backgroundColor: '#2b2b2b' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: '#ffffff' }}>Date</TableCell>
              <TableCell sx={{ color: '#ffffff' }}>Donor</TableCell>
              <TableCell sx={{ color: '#ffffff' }}>Email</TableCell>
              <TableCell sx={{ color: '#ffffff' }} align="right">Amount</TableCell>
              <TableCell sx={{ color: '#ffffff' }}>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {donations.map((donation) => (
              <TableRow key={donation.id}>
                <TableCell sx={{ color: '#ffffff' }}>
                  {new Date(donation.date).toLocaleDateString()}
                </TableCell>
                <TableCell sx={{ color: '#ffffff' }}>
                  {donation.isAnonymous ? 'Anonymous' : donation.donorName}
                </TableCell>
                <TableCell sx={{ color: '#ffffff' }}>{donation.email}</TableCell>
                <TableCell sx={{ color: '#ffffff' }} align="right">
                  {formatCurrency(donation.amount)}
                </TableCell>
                <TableCell sx={{ color: '#ffffff' }}>{donation.message || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={isGoalDialogOpen} onClose={() => setIsGoalDialogOpen(false)}>
        <DialogTitle>Update Donation Goal</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="New Goal Amount"
            type="number"
            value={newGoal}
            onChange={(e) => setNewGoal(Number(e.target.value))}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsGoalDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleUpdateGoal} variant="contained" sx={{ backgroundColor: '#5ba85b' }}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminDonations;
