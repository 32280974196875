import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
  Chip,
  Pagination,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ref, onValue } from 'firebase/database';
import { db } from '../firebase/config';
import PageContainer from '../components/PageContainer';
import { TranslatedContent } from './admin/AdminNews';
import { getLangCode } from '../utils/langCode';
import ScrollableCalendar from '../components/ScrollableCalendar/ScrollableCalendar';
import dayjs, { Dayjs } from 'dayjs';

interface NewsItem {
  id: string;
  title: TranslatedContent;
  description: TranslatedContent;
  content: Array<{ type: string, value: TranslatedContent }>;
  imageUrl: string;
  useExcerpt: boolean;
  expirationDate: string;
  createdAt: string;
  category?: string;
}

const ITEMS_PER_PAGE = 5;

const News: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
  const [filteredNews, setFilteredNews] = useState<NewsItem[]>([]);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const newsRef = ref(db, 'news');
    const unsubscribe = onValue(newsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const newsArray = Object.entries(data).map(([key, value]: [string, any]) => ({
          ...value,
          id: key
        }))
          .filter(item => new Date(item.expirationDate) > new Date())
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        setNewsItems(newsArray);
        setFilteredNews(newsArray);
      } else {
        setNewsItems([]);
        setFilteredNews([]);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const filtered = newsItems.filter(news => {
        const newsDate = dayjs(news.createdAt);
        return newsDate.isSame(selectedDate, 'day');
      });
      setFilteredNews(filtered);
      setPage(1);
    } else {
      setFilteredNews(newsItems);
    }
  }, [selectedDate, newsItems]);

  const handleDateSelect = (date: Dayjs) => {
    setSelectedDate(selectedDate?.isSame(date, 'day') ? null : date);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const getAvailableDates = () => {
    const dates = newsItems.map(item => dayjs(item.createdAt));
    const uniqueDates = Array.from(new Set(dates.map(date => date.format('YYYY-MM-DD'))))
      .map(dateStr => dayjs(dateStr))
      .sort((a, b) => b.valueOf() - a.valueOf());
    return uniqueDates;
  };

  const paginatedNews = filteredNews.slice(
    (page - 1) * ITEMS_PER_PAGE,
    page * ITEMS_PER_PAGE
  );

  const pageCount = Math.ceil(filteredNews.length / ITEMS_PER_PAGE);

  return (
    <PageContainer>
      <Container sx={{ py: 8 }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h3" component="h1" gutterBottom align="center">
            {t('news.title')}
          </Typography>
          <Typography variant="h6" paragraph align="center" color="text.secondary" sx={{ mb: 4 }}>
            {t('news.subtitle')}
          </Typography>

          <Box sx={{ 
            width: '100%',
            maxWidth: 800, 
            mx: 'auto',   
            mb: 4 
          }}>
            <ScrollableCalendar
              selectedDate={selectedDate}
              onDateSelect={handleDateSelect}
              dates={getAvailableDates()}
            />
          </Box>

          <Grid container spacing={4}>
            {paginatedNews.map((item, index) => (
              <Grid item xs={12} key={item.id}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Card
                    sx={{
                      height: { xs: '500px', md: '250px' },
                      display: 'flex',
                      cursor: 'pointer',
                      '&:hover': {
                        boxShadow: 6,
                      },
                    }}
                    onClick={() => navigate(`/news/${item.id}`)}
                  >
                    <Grid container>
                      <Grid item xs={12} md={4} sx={{ height: { xs: '250px', md: '250px' } }}>
                        <CardMedia
                          component="img"
                          height="250"
                          image={item.imageUrl || 'https://via.placeholder.com/800x400'}
                          alt={item.title[getLangCode()]}
                          sx={{
                            objectFit: 'cover',
                            height: '100%',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <CardContent
                          sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            p: 3,
                          }}
                        >
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1.5 }}>
                            <Chip
                              label={t(`news.categories.${item.category || 'news'}`)}
                              color="primary"
                              size="small"
                              sx={{
                                fontWeight: 'medium',
                                '& .MuiChip-label': {
                                  px: 2,
                                },
                              }}
                            />
                            <Typography variant="body2" color="text.secondary">
                              {new Date(item.createdAt).toLocaleDateString()}
                            </Typography>
                          </Box>
                          <Typography
                            variant="h6"
                            sx={{
                              mb: 1.5,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {item.title[getLangCode()]}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: 'vertical',
                              lineHeight: 1.5,
                              mb: 2,
                            }}
                          >
                            {item.description[getLangCode()]}
                          </Typography>
                        </CardContent>
                      </Grid>
                    </Grid>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>

          {pageCount > 1 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                color="primary"
                size="large"
              />
            </Box>
          )}
        </motion.div>
      </Container>
    </PageContainer>
  );
};

export default News;
