import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Pagination,
  Box,
  CircularProgress,
  styled,
} from '@mui/material';
import { ref, onValue, off, Query, query, orderByChild } from 'firebase/database';
import { db } from '../../firebase/config';
import Message from '../../components/admin/Message/Message';

interface ContactMessage {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  message: string;
  sentAt: string;
}

const Header = styled('div')({
  marginBottom: '2rem',
  '& h1': {
    color: '#5ba85b',
    textShadow: '0 0 10px rgba(91, 168, 91, 0.3)',
  },
});

const PaginationContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '2rem',
  marginBottom: '2rem',
  '& .MuiPagination-ul': {
    '& .MuiPaginationItem-root': {
      color: '#ffffff',
      '&.Mui-selected': {
        backgroundColor: '#5ba85b',
      },
    },
  },
});

const LoadingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '200px',
});

const ITEMS_PER_PAGE = 10;

const AdminMessages = () => {
  const [messages, setMessages] = useState<ContactMessage[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const messagesRef = ref(db, 'contacts');
    const messagesQuery: Query = query(messagesRef, orderByChild('sentAt'));

    const handleData = (snapshot: any) => {
      if (snapshot.exists()) {
        const messagesData = snapshot.val();
        const messagesArray = Object.entries(messagesData).map(([id, data]: [string, any]) => ({
          id,
          ...data,
        }));
        
        // Sort messages by sentAt in descending order
        messagesArray.sort((a, b) => new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime());
        
        setMessages(messagesArray);
        setTotalPages(Math.ceil(messagesArray.length / ITEMS_PER_PAGE));
      }
      setLoading(false);
    };

    onValue(messagesQuery, handleData);

    return () => {
      // Cleanup subscription
      off(messagesQuery);
    };
  }, []);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getCurrentPageMessages = () => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return messages.slice(startIndex, endIndex);
  };

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress sx={{ color: '#5ba85b' }} />
      </LoadingContainer>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header>
        <h1>Messages</h1>
        <Typography variant="subtitle1" color="text.secondary">
          {messages.length} messages received
        </Typography>
      </Header>

      {messages.length === 0 ? (
        <Typography variant="h6" align="center" color="text.secondary">
          No messages yet
        </Typography>
      ) : (
        <>
          {getCurrentPageMessages().map((message) => (
            <Message
              key={message.id}
              firstName={message.firstName}
              lastName={message.lastName}
              email={message.email}
              phoneNumber={message.phoneNumber}
              message={message.message}
              sentAt={message.sentAt}
            />
          ))}

          {totalPages > 1 && (
            <PaginationContainer>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                size="large"
              />
            </PaginationContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default AdminMessages;
