import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBgxHcK7-TC0IejuL32rwndSKHz1mp1Yuw",
  authDomain: "cabral-3bd5f.firebaseapp.com",
  projectId: "cabral-3bd5f",
  storageBucket: "cabral-3bd5f.firebasestorage.app",
  messagingSenderId: "899837984158",
  appId: "1:899837984158:web:a54f4d9412ee20077f2a8f",
  measurementId: "G-5989Q0JRME",
  databaseURL: "https://cabral-3bd5f-default-rtdb.firebaseio.com"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getDatabase(app);

export default app;
