import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/config';
import { useNavigate } from 'react-router-dom';

interface LoginCredentials {
  id: string;
  password: string;
}

const AdminLoginContainer = styled('div')({
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#2b2b2b',
});

const LoginBox = styled('div')({
  backgroundColor: '#383838',
  padding: '2.5rem',
  borderRadius: '15px',
  boxShadow: '0 0 20px rgba(91, 168, 91, 0.2)',
  width: '100%',
  maxWidth: '400px',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 0 30px rgba(91, 168, 91, 0.3)',
  },
});

const LoginTitle = styled('h1')({
  color: '#ffffff',
  textAlign: 'center',
  marginBottom: '2rem',
  fontSize: '2rem',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  textShadow: '0 0 10px rgba(91, 168, 91, 0.5)',
});

const LoginForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
});

const InputGroup = styled('div')({
  position: 'relative',
  '& input': {
    width: '100%',
    padding: '12px',
    backgroundColor: '#2b2b2b',
    border: 'none',
    borderRadius: '5px',
    color: '#ffffff',
    fontSize: '1rem',
    transition: 'all 0.3s ease',
    '&:focus': {
      outline: 'none',
      boxShadow: '0 0 10px rgba(91, 168, 91, 0.3)',
    },
  },
});

const NeonLine = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  height: '2px',
  width: 0,
  backgroundColor: '#5ba85b',
  transition: 'width 0.3s ease',
  boxShadow: '0 0 10px #5ba85b',
  'input:focus + &': {
    width: '100%',
  },
});

const LoginButton = styled('button')({
  backgroundColor: '#5ba85b',
  color: 'white',
  padding: '12px',
  border: 'none',
  borderRadius: '5px',
  fontSize: '1rem',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  marginTop: '1rem',
  '&:hover': {
    backgroundColor: '#4c8f4c',
    boxShadow: '0 0 15px rgba(91, 168, 91, 0.5)',
  },
  '&:active': {
    transform: 'scale(0.98)',
  },
});

const ErrorMessage = styled('div')({
  color: '#ff4444',
  textAlign: 'center',
  marginTop: '1rem',
  fontSize: '0.9rem',
});

const AdminLogin: React.FC = () => {
  const [credentials, setCredentials] = useState<LoginCredentials>({
    id: '',
    password: '',
  });
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setError('');
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, credentials.id, credentials.password);
      // Redirect to dashboard home
      navigate('/dashboard/home');
    } catch (err) {
      setError('Invalid credentials. Please try again.');
      console.error('Login error:', err);
    }
  };

  return (
    <AdminLoginContainer>
      <LoginBox>
        <LoginTitle>Admin Login</LoginTitle>
        <LoginForm onSubmit={handleSubmit}>
          <InputGroup>
            <input
              type="email"
              name="id"
              value={credentials.id}
              onChange={handleChange}
              placeholder="Email"
              required
            />
            <NeonLine />
          </InputGroup>
          <InputGroup>
            <input
              type="password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              placeholder="Password"
              required
            />
            <NeonLine />
          </InputGroup>
          {error && (
            <ErrorMessage>{error}</ErrorMessage>
          )}
          <LoginButton type="submit">
            Login
          </LoginButton>
        </LoginForm>
      </LoginBox>
    </AdminLoginContainer>
  );
};

export default AdminLogin;
