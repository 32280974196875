import React, { useState } from 'react';
import { Box, IconButton, Grid, useTheme, useMediaQuery } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ProgramCard from '../ProgramCard/ProgramCard';
import { ProgramCard as ProgramCardType } from '../../data/programCards';

interface ProgramCarouselProps {
  cards: ProgramCardType[];
  itemsPerPage?: number;
}

const ProgramCarousel: React.FC<ProgramCarouselProps> = ({ cards, itemsPerPage = 3 }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  // Adjust items per page based on screen size
  const actualItemsPerPage = isMobile ? 1 : isTablet ? 2 : itemsPerPage;
  const totalPages = Math.ceil(cards.length / actualItemsPerPage);

  const handleNext = () => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  };

  const handlePrev = () => {
    setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  };

  const getVisibleCards = () => {
    const start = currentPage * actualItemsPerPage;
    return cards.slice(start, start + actualItemsPerPage);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', my: 4 }}>
      <IconButton
        onClick={handlePrev}
        sx={{
          position: 'absolute',
          left: -20,
          top: '50%',
          transform: 'translateY(-50%)',
          bgcolor: 'background.paper',
          boxShadow: 2,
          '&:hover': { bgcolor: 'background.paper' },
          zIndex: 1,
        }}
      >
        <ArrowBackIcon />
      </IconButton>

      <IconButton
        onClick={handleNext}
        sx={{
          position: 'absolute',
          right: -20,
          top: '50%',
          transform: 'translateY(-50%)',
          bgcolor: 'background.paper',
          boxShadow: 2,
          '&:hover': { bgcolor: 'background.paper' },
          zIndex: 1,
        }}
      >
        <ArrowForwardIcon />
      </IconButton>

      <Box sx={{ overflow: 'hidden', px: 2 }}>
        <AnimatePresence mode="wait">
          <motion.div
            key={currentPage}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
          >
            <Grid container spacing={4}>
              {getVisibleCards().map((card) => (
                <Grid item xs={12} md={12 / actualItemsPerPage} key={card.id}>
                  <ProgramCard card={card} />
                </Grid>
              ))}
            </Grid>
          </motion.div>
        </AnimatePresence>
      </Box>
    </Box>
  );
};

export default ProgramCarousel;
