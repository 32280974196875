import AWS from 'aws-sdk';

const s3 = new AWS.S3({
    accessKeyId: "AKIA2S2Y4DROQWLPP2NR",
    secretAccessKey: "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",
    region: "eu-north-1"
});

export const uploadToS3 = async (file: File): Promise<string> => {
    const fileName = `news/${Date.now()}-${file.name}`;

    const params = {
        Bucket: 'phytt',
        Key: fileName,
        Body: file,
    };

    try {
        const data = await s3.upload(params).promise();
        return data.Location;
    } catch (error) {
        console.error('Error uploading to S3:', error);
        throw error;
    }
};
