import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { motion, AnimatePresence } from 'framer-motion';
import { ref, query, orderByChild, limitToLast, onValue } from 'firebase/database';
import { db } from '../../firebase/config';
import { TranslatedContent } from '../../pages/admin/AdminNews';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

interface NewsItem {
  id: string;
  title: TranslatedContent;
  content: Array<{ type: string, value: string }>;
  imageUrl: string;
  expirationDate: string;
  description: TranslatedContent;
  createdAt: string;
}

const LatestNews: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [latestNews, setLatestNews] = useState<NewsItem[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const newsRef = ref(db, 'news');
    const newsQuery = query(newsRef, orderByChild('createdAt'), limitToLast(3));

    const unsubscribe = onValue(newsQuery, (snapshot) => {
      const newsData = snapshot.val();
      if (newsData) {
        const newsArray = Object.entries(newsData).map(([id, data]) => ({
          id,
          ...(data as Omit<NewsItem, 'id'>)
        }));
        setLatestNews(newsArray.reverse());
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (latestNews.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % latestNews.length);
      }, 7000);

      return () => clearInterval(interval);
    }
  }, [latestNews.length]);

  const currentNews = latestNews[currentIndex];

  return (
    <Box sx={{ py: 8, backgroundColor: 'background.paper' }}>
      <Container maxWidth="lg">
        {/* <Typography
          variant="h2"
          component="h2"
          align="center"
          gutterBottom
          sx={{ mb: 6 }}
        >
          {t('news.title')}
        </Typography> */}

        <AnimatePresence mode="wait">
          {currentNews && (
            <motion.div
              key={currentNews.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.7, ease: "easeInOut" }}
            >
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                  <motion.div
                    initial={{ x: -100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.7, ease: "easeInOut", delay: 0.1 }}
                  >
                    <Box
                      component="img"
                      src={currentNews.imageUrl}
                      alt={currentNews.title.en}
                      sx={{
                        width: '100%',
                        height: 400,
                        objectFit: 'cover',
                        borderRadius: 2,
                        boxShadow: 3,
                      }}
                    />
                  </motion.div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <motion.div
                    initial={{ x: 100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: 100, opacity: 0 }}
                    transition={{ duration: 0.7, ease: "easeInOut", delay: 0.1 }}
                  >
                    <Box sx={{ p: { xs: 2, md: 4 } }}>
                      <Typography
                        variant={isMobile ? "h6" : "h5"}
                        component="h3"
                        gutterBottom
                        sx={{
                          fontSize: isMobile ? '1.1rem' : '1.5rem',
                          mb: 2,
                        }}
                      >
                        {currentNews.title.en}
                      </Typography>

                      <Typography
                        variant="body1"
                        sx={{
                          mb: 3,
                          fontSize: isMobile ? '0.9rem' : '1rem',
                          lineHeight: isMobile ? 1.5 : 1.7,
                        }}
                      >
                        {currentNews.description.en.slice(0, 200) + '...'}
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mt: 3,
                          gap: 2,
                          flexDirection: 'row',
                          width: '100%',
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => navigate('/news')}
                          fullWidth
                          sx={{
                            fontSize: isMobile ? '0.8rem' : '1rem',
                            py: isMobile ? 1 : 1.5,
                          }}
                        >
                          {t('home.latestNews.readMore')}
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => navigate('/news')}
                          fullWidth
                          sx={{
                            fontSize: isMobile ? '0.8rem' : '1rem',
                            py: isMobile ? 1 : 1.5,
                          }}
                        >
                          {t('home.latestNews.otherNews')}
                        </Button>
                      </Box>
                    </Box>
                  </motion.div>
                </Grid>
              </Grid>
            </motion.div>
          )}
        </AnimatePresence>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 4,
            gap: 1
          }}
        >
          {latestNews.map((_, index) => (
            <Box
              key={index}
              sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: index === currentIndex ? 'primary.main' : 'grey.300',
                transition: 'background-color 0.3s',
                cursor: 'pointer'
              }}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default LatestNews;
