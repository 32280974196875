export interface ProgramCard {
  id: number;
  titleKey: string;
  image: string;
  contentKey: string;
}

export const programCards: ProgramCard[] = [
  {
    id: 1,
    titleKey: 'program.item1.title',
    image: `${process.env.PUBLIC_URL}/images/vision/federal.png`,
    contentKey: 'program.item1.description'
  },
  {
    id: 2,
    titleKey: 'program.item2.title',
    image: `${process.env.PUBLIC_URL}/images/vision/modernisation.jpg`,
    contentKey: 'program.item2.description'
  },
  {
    id: 3,
    titleKey: 'program.item3.title',
    image: `${process.env.PUBLIC_URL}/images/vision/agriculture.jpg`,
    contentKey: 'program.item3.description'
  },
  {
    id: 4,
    titleKey: 'program.item4.title',
    image: `${process.env.PUBLIC_URL}/images/vision/logement.webp`,
    contentKey: 'program.item4.description'
  },
  {
    id: 5,
    titleKey: 'program.item5.title',
    image: `${process.env.PUBLIC_URL}/images/vision/emploi.webp`,
    contentKey: 'program.item5.description'
  },
  {
    id: 6,
    titleKey: 'program.item6.title',
    image: `${process.env.PUBLIC_URL}/images/vision/education.jpg`,
    contentKey: 'program.item6.description'
  },
  {
    id: 7,
    titleKey: 'program.item7.title',
    image: `${process.env.PUBLIC_URL}/images/vision/health.jpg`,
    contentKey: 'program.item7.description'
  },
  {
    id: 8,
    titleKey: 'program.item8.title',
    image: `${process.env.PUBLIC_URL}/images/vision/culture.jpg`,
    contentKey: 'program.item8.description'
  },
  {
    id: 9,
    titleKey: 'program.item9.title',
    image: `${process.env.PUBLIC_URL}/images/vision/sport.jpg`,
    contentKey: 'program.item9.description'
  },
  {
    id: 10,
    titleKey: 'program.item10.title',
    image: `${process.env.PUBLIC_URL}/images/vision/citoyen.jpg`,
    contentKey: 'program.item10.description'
  },
  {
    id: 11,
    titleKey: 'program.item11.title',
    image: `${process.env.PUBLIC_URL}/images/vision/diplomacy.jpg`,
    contentKey: 'program.item11.description'
  }
];
