import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Collapse,
  styled,
} from '@mui/material';
import { format } from 'date-fns';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

interface MessageProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  message: string;
  sentAt: string;
}

const MessageCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: '#383838',
  color: '#ffffff',
  '& .MuiTypography-root': {
    color: '#ffffff',
  },
}));

const HeaderRow = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '8px',
});

const ContactRow = styled(Box)({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  marginTop: '16px',
  '& .contact-item': {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
});

const Message: React.FC<MessageProps> = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  message,
  sentAt,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <MessageCard>
      <CardContent>
        <HeaderRow>
          <Typography variant="h6">
            {firstName} {lastName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {format(new Date(sentAt), 'PPp')}
          </Typography>
        </HeaderRow>

        <Box>
          <Collapse in={expanded} collapsedSize={72}>
            <Typography
              variant="body1"
              sx={{
                whiteSpace: 'pre-wrap',
                mb: 2,
              }}
            >
              {message}
            </Typography>
          </Collapse>
          {message.length > 150 && (
            <Button
              onClick={toggleExpanded}
              sx={{ color: '#5ba85b' }}
              size="small"
            >
              {expanded ? 'Show Less' : 'Read More'}
            </Button>
          )}
        </Box>

        <ContactRow>
          <Box className="contact-item">
            <PhoneIcon sx={{ color: '#5ba85b' }} />
            <Typography>{phoneNumber}</Typography>
          </Box>
          <Box className="contact-item">
            <EmailIcon sx={{ color: '#5ba85b' }} />
            <Typography>{email}</Typography>
          </Box>
        </ContactRow>
      </CardContent>
    </MessageCard>
  );
};

export default Message;
