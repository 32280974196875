import React, { useRef, useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import dayjs, { Dayjs } from 'dayjs';

interface ScrollableCalendarProps {
  selectedDate: Dayjs | null;
  onDateSelect: (date: Dayjs) => void;
  dates: Dayjs[];
}

const ScrollableCalendar: React.FC<ScrollableCalendarProps> = ({
  selectedDate,
  onDateSelect,
  dates,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showArrows, setShowArrows] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const checkScrollability = () => {
    if (scrollContainerRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = scrollContainerRef.current;
      setShowArrows(scrollWidth > clientWidth);
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    checkScrollability();
    window.addEventListener('resize', checkScrollability);
    return () => window.removeEventListener('resize', checkScrollability);
  }, [dates]);

  const handleScroll = () => {
    checkScrollability();
  };

  const scrollTo = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', mb: 4 }}>
      {showArrows && showLeftArrow && (
        <IconButton
          onClick={() => scrollTo('left')}
          sx={{
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
            backgroundColor: 'background.paper',
            boxShadow: 1,
            '&:hover': { backgroundColor: 'background.paper' },
          }}
        >
          <ChevronLeft />
        </IconButton>
      )}

      <Box
        ref={scrollContainerRef}
        onScroll={handleScroll}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': { display: 'none' },
          px: showArrows ? 6 : 0,
          py: 1,
          gap: 1,
          justifyContent: dates.length <= 7 ? 'center' : 'flex-start',
          margin: '0 auto',
          maxWidth: '100%',
        }}
      >
        {dates.map((date) => (
          <Box
            key={date.format('YYYY-MM-DD')}
            onClick={() => onDateSelect(date)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              p: 1,
              borderRadius: 1,
              backgroundColor: selectedDate?.isSame(date, 'day')
                ? 'primary.main'
                : 'background.paper',
              color: selectedDate?.isSame(date, 'day')
                ? 'primary.contrastText'
                : 'text.primary',
              boxShadow: 1,
              transition: 'all 0.2s',
              '&:hover': {
                backgroundColor: selectedDate?.isSame(date, 'day')
                  ? 'primary.dark'
                  : 'action.hover',
              },
            }}
          >
            <Box sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
              {date.format('ddd')}
            </Box>
            <Box sx={{ fontSize: '1rem' }}>
              {date.format('D')}
            </Box>
          </Box>
        ))}
      </Box>

      {showArrows && showRightArrow && (
        <IconButton
          onClick={() => scrollTo('right')}
          sx={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
            backgroundColor: 'background.paper',
            boxShadow: 1,
            '&:hover': { backgroundColor: 'background.paper' },
          }}
        >
          <ChevronRight />
        </IconButton>
      )}
    </Box>
  );
};

export default ScrollableCalendar;
