import React, { useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Alert,
  CircularProgress,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import PageContainer from '../components/PageContainer';
import { initializePayment } from '../services/paymentService';

const Donate: React.FC = () => {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    amount: '',
    name: '',
    email: '',
    phone: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      if (!formData.amount || isNaN(Number(formData.amount)) || Number(formData.amount) <= 0) {
        throw new Error(t('donate.form.errors.invalidAmount'));
      }
      if (!formData.email || !formData.email.includes('@')) {
        throw new Error(t('donate.form.errors.invalidEmail'));
      }

      const response = await initializePayment({
        email: formData.email,
        amount: Number(formData.amount),
        description: `Donation from ${formData.name || 'Anonymous'}`,
      });

      if (response.authorization_url) {
        window.location.href = response.authorization_url;
      } else {
        throw new Error(t('donate.form.errors.paymentInitFailed'));
      }
    } catch (err: any) {
      setError(err.message || t('donate.form.errors.general'));
      setIsLoading(false);
    }
  };

  return (
    <PageContainer>
      <Container sx={{ py: 8 }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h3" component="h1" gutterBottom align="center">
            {t('donate.title')}
          </Typography>
          <Typography variant="h6" paragraph align="center" color="text.secondary">
            {t('donate.subtitle')}
          </Typography>

          {/* Progress Bar */}
          <Box sx={{ my: 4 }}>
            <Typography variant="h6" gutterBottom>
              {t('donate.campaign_goal')}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={60}
              sx={{
                height: 10,
                borderRadius: 5,
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
              <Typography variant="body2" color="text.secondary">
                {t('donate.collected')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t('donate.goal')}
              </Typography>
            </Box>
          </Box>

          <Grid container spacing={4}>
            {/* Donation Form */}
            <Grid item xs={12} md={7}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {t('donate.form.title')}
                  </Typography>
                  {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                      {error}
                    </Alert>
                  )}
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="amount"
                          label={t('donate.form.amount')}
                          variant="outlined"
                          type="number"
                          value={formData.amount}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="name"
                          label={t('donate.form.name')}
                          variant="outlined"
                          value={formData.name}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="email"
                          label={t('donate.form.email')}
                          variant="outlined"
                          type="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="phone"
                          label={t('donate.form.phone')}
                          variant="outlined"
                          value={formData.phone}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="large"
                          disabled={isLoading}
                          sx={{ position: 'relative' }}
                        >
                          {isLoading ? (
                            <>
                              <CircularProgress
                                size={24}
                                sx={{
                                  position: 'absolute',
                                  left: '50%',
                                  marginLeft: '-12px',
                                }}
                              />
                              {t('donate.form.processing')}
                            </>
                          ) : (
                            t('donate.form.submit')
                          )}
                        </Button>
                        <Typography
                          variant="caption"
                          display="block"
                          align="center"
                          sx={{ mt: 1 }}
                        >
                          {t('donate.form.secure_payment')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>

            {/* Why Donate Section */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {t('donate.why_donate.title')}
                  </Typography>
                  <Typography paragraph>
                    {t('donate.why_donate.description')}
                  </Typography>
                  <List>
                    {(t('donate.why_donate.reasons', { returnObjects: true }) as string[]).map(
                      (reason: string, index: number) => (
                        <ListItem key={index}>
                          <ListItemText primary={reason} />
                        </ListItem>
                      )
                    )}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </motion.div>
      </Container>
    </PageContainer>
  );
};

export default Donate;
