import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Box,
  IconButton,
  Alert,
  Snackbar,
} from '@mui/material';
import { motion } from 'framer-motion';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ref, push } from 'firebase/database';
import { db } from '../firebase/config';
import YouTubeIcon from '@mui/icons-material/YouTube';

interface ContactForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  message: string;
}

const initialFormState: ContactForm = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  message: '',
};

const Contact = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<ContactForm>(initialFormState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const contactsRef = ref(db, 'contacts');
      await push(contactsRef, {
        ...formData,
        sentAt: new Date().toISOString(),
      });

      setSnackbar({
        open: true,
        message: t('contact.form.success'),
        severity: 'success',
      });
      setFormData(initialFormState);
    } catch (error) {
      console.error('Error submitting form:', error);
      setSnackbar({
        open: true,
        message: t('contact.form.error'),
        severity: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <Container maxWidth="lg">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h3" component="h1" gutterBottom align="center">
          {t('contact.title')}
        </Typography>
        <Typography variant="h6" paragraph align="center" color="text.secondary">
          {t('contact.subtitle')}
        </Typography>

        <Grid container spacing={4}>
          {/* Contact Form */}
          <Grid item xs={12} md={7}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  {t('contact.form.title')}
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label={t('contact.form.first_name')}
                        variant="outlined"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label={t('contact.form.last_name')}
                        variant="outlined"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={t('contact.form.email')}
                        variant="outlined"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={t('contact.form.phone')}
                        variant="outlined"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={t('contact.form.message')}
                        variant="outlined"
                        multiline
                        rows={4}
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? t('contact.form.submitting') : t('contact.form.submit')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12} md={5}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  {t('contact.info.title')}
                </Typography>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body1" paragraph>
                    {t('contact.info.address')}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {t('contact.info.phone')}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {t('contact.info.email')}
                  </Typography>
                </Box>
                <Typography variant="h6" gutterBottom>
                  {t('contact.info.follow_us')}
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <IconButton color="primary" component="a" href="https://www.facebook.com/groups/160719334835343">
                    <FacebookIcon />
                  </IconButton>
                  <IconButton color="primary" component="a" href="https://x.com/Pcrn_Officiel">
                    <TwitterIcon />
                  </IconButton>
                  <IconButton color="primary" component="a" href="https://www.instagram.com/pcrn_officiel/">
                    <InstagramIcon />
                  </IconButton>
                  <IconButton color="primary" component="a" href="https://www.youtube.com/@pcrnofficiel93">
                    <YouTubeIcon />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </motion.div>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Contact;
