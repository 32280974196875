export const colors = {
  // Primary colors (Green)
  green: {
    main: '#007A3D',  // Cameroon flag green
    light: '#1A9850',
    dark: '#005C2E',
  },
  // Secondary colors (Red)
  red: {
    main: '#CE1126',  // Cameroon flag red
    light: '#E63946',
    dark: '#9B0C1C',
  },
  // Accent colors (Yellow)
  yellow: {
    main: '#FCD116',  // Cameroon flag yellow
    light: '#FFE066',
    dark: '#D4AF37',
  },
  // Background colors
  background: {
    default: '#F5F5F5',
    paper: '#FFFFFF',
    dark: '#1A1A1A',
  },
  // Text colors
  text: {
    primary: '#1A1A1A',
    secondary: '#4A4A4A',
    light: '#FFFFFF',
  },
  // Additional UI colors
  ui: {
    success: '#28A745',
    warning: '#FFC107',
    error: '#DC3545',
    info: '#17A2B8',
  },
} as const;

export type ColorKeys = keyof typeof colors;
