import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Home from './pages/Home';
import News from './pages/News';
import NewsDetail from './pages/news/NewsDetail';
import Program from './pages/Program';
import Donate from './pages/Donate';
import Scrutateurs from './pages/Scrutateurs';
import Contact from './pages/Contact';
import AdminLogin from './pages/AdminLogin';
import AdminProgram from './pages/admin/AdminProgram';
import AdminNews from './pages/admin/AdminNews';
import AdminPollWorkers from './pages/admin/AdminPollWorkers';
import AdminDonations from './pages/admin/AdminDonations';
import AdminHome from './pages/admin/AdminHome';
import AdminMessages from './pages/admin/AdminMessages';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LanguageSwitcher from './components/LanguageSwitcher';
import ProtectedRoute from './components/ProtectedRoute';
import AdminLayout from './components/admin/AdminLayout';
import './i18n';
import PaymentStatus from './pages/PaymentStatus';

const App: React.FC = () => {
  useEffect(() => {
    // Reset any global scroll settings that might be set by fullpage.js
    document.documentElement.style.overflow = '';
    document.body.style.overflow = '';
    document.documentElement.style.height = '';
    document.body.style.height = '';
    document.documentElement.style.position = '';
    document.body.style.position = '';
  }, []); // Run once on mount

  const MainLayout = () => (
    <>
      <Navbar />
      <LanguageSwitcher />
      <main style={{ flex: 1 }}>
        <Outlet />
      </main>
      <Footer />
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div className="App" style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e7eb 100%)'
        }}>
          <Routes>
            {/* Main Layout */}
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Home />} />
              <Route path="/news" element={<News />} />
              <Route path="/news/:id" element={<NewsDetail />} />
              <Route path="/program" element={<Program />} />
              <Route path="/donate" element={<Donate />} />
              <Route path="/scrutateurs" element={<Scrutateurs />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/payment-status" element={<PaymentStatus />} />
            </Route>

            {/* Admin Routes */}
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/dashboard" element={
              <ProtectedRoute>
                <AdminLayout />
              </ProtectedRoute>
            }>
              <Route index element={<Navigate to="home" replace />} />
              <Route path="home" element={<AdminHome />} />
              <Route path="program" element={<AdminProgram />} />
              <Route path="news" element={<AdminNews />} />
              <Route path="messages" element={<AdminMessages />} />
              <Route path="poll-workers" element={<AdminPollWorkers />} />
              <Route path="donations" element={<AdminDonations />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
