import { v4 as uuidv4 } from 'uuid';

const NOTCHPAY_PUBLIC_KEY = "pk_test.oLYsJ8b6Gua1g4GVXtWUoxxiA4E8C84uHtoVKFKL3tPLUFAZUOYoskwjhiC5wgFqiI7dVRz28J4MbLtBJEpeUc1FSAjgPZwgB5I7AR7lzJtfquLeHhutQaExn7TYu";
const BASE_URL = 'https://api.notchpay.co';

interface InitializePaymentProps {
  email: string;
  amount: number;
  description?: string;
  currency?: string;
}

interface PaymentResponse {
  authorization_url: string;
  reference: string;
}

const generateReference = (): string => {
  return `DON_${Date.now()}_${Math.random().toString(36).substring(2, 15)}`;
};

export const initializePayment = async ({
  email,
  amount,
  description = 'Donation à la Fondation Cabral',
  currency = 'XAF'
}: InitializePaymentProps): Promise<PaymentResponse> => {
  try {
    const reference = generateReference();
    const response = await fetch(`${BASE_URL}/payments/initialize`, {
      method: 'POST',
      headers: {
        Authorization: NOTCHPAY_PUBLIC_KEY,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        amount: amount.toString(),
        currency,
        description,
        reference,
        callback: `${window.location.origin}/payment-status`
      })
    });

    console.log(response)
    
    if (response.status === 422) {
      throw new Error('The donation amount must be lower than or equal to 500,000 FCFA');
    }
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return {
      authorization_url: data.authorization_url,
      reference: data.reference,
    };
  } catch (error) {
    console.error('Payment initialization failed:', error);
    throw error;
  }
};
