import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import { motion } from 'framer-motion';
import { ref, set } from 'firebase/database';
import { db } from '../firebase/config';

interface PaymentStatusProps {}

const PaymentStatus: React.FC<PaymentStatusProps> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState<string>('');
  const [reference, setReference] = useState<string>('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paymentStatus = params.get('status');
    const paymentRef = params.get('reference');
    const trxRef = params.get('trxref');

    if (paymentStatus && paymentRef) {
      setStatus(paymentStatus);
      setReference(paymentRef);
      
      // Store payment status in Firebase
      const paymentStatusRef = ref(db, `payments/${paymentRef}`);
      set(paymentStatusRef, {
        status: paymentStatus,
        trxref: trxRef,
        timestamp: new Date().toISOString(),
      }).catch(console.error);
    }

    setIsLoading(false);
  }, [location]);

  const getStatusColor = () => {
    switch (status.toLowerCase()) {
      case 'success':
        return 'success.main';
      case 'failed':
        return 'error.main';
      default:
        return 'warning.main';
    }
  };

  const getStatusIcon = () => {
    switch (status.toLowerCase()) {
      case 'success':
        return <CheckCircleIcon sx={{ fontSize: 64, color: 'success.main' }} />;
      case 'failed':
        return <ErrorIcon sx={{ fontSize: 64, color: 'error.main' }} />;
      default:
        return <PendingIcon sx={{ fontSize: 64, color: 'warning.main' }} />;
    }
  };

  const getStatusMessage = () => {
    switch (status.toLowerCase()) {
      case 'success':
        return t('payment.successMessage');
      case 'failed':
        return t('payment.failedMessage');
      default:
        return t('payment.pendingMessage');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          py: 4,
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {getStatusIcon()}
            
            <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 3 }}>
              {t('payment.status')}
            </Typography>

            <Typography
              variant="h5"
              sx={{ color: getStatusColor(), mb: 2 }}
            >
              {status.toUpperCase()}
            </Typography>

            <Typography variant="body1" sx={{ mb: 4 }}>
              {getStatusMessage()}
            </Typography>

            {reference && (
              <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
                {t('payment.reference')}: {reference}
              </Typography>
            )}

            <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/')}
                sx={{ minWidth: 200 }}
              >
                {t('payment.backToHome')}
              </Button>

              {status.toLowerCase() === 'failed' && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate('/donate')}
                  sx={{ minWidth: 200 }}
                >
                  {t('payment.tryAgain')}
                </Button>
              )}
            </Box>
          </motion.div>
        )}
      </Box>
    </Container>
  );
};

export default PaymentStatus;
